import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    meta: { title: "核创能源科技" },
    redirect: '/Index'
  },
  {
    path: '/MIndex',
    name: 'MIndex',
    meta: { title: "核创能源科技" },
    component: () => import('../components/mobile/Index.vue')
  },
  {
    path: '/Index',
    name: '首页',
    meta: { title: "核创能源科技" },
    component: () => import('../components/pc/Index.vue')
  },
  {
    path: '/profile',
    name: '公司简介',
    meta: { title: "公司简介-核创能源科技" },
    component: () => import('../components/pc/Profile.vue'),
    children: [
      {
        path: '',
        name: '公司简介',
        meta: { title: "公司简介-核创能源科技" },
        component: () => import('../components/pc/profile/Index.vue')
      },
      {
        path: 'abouts',
        name: '公司简介',
        meta: { title: "公司简介-核创能源科技" },
        component: () => import('../components/pc/profile/Abouts.vue')
      },
      {
        path: 'progress',
        name: '发展历程',
        meta: { title: "发展历程-核创能源科技" },
        component: () => import('../components/pc/profile/Progress.vue')
      },
      {
        path: 'duty',
        name: '使命与责任',
        meta: { title: "使命与责任-核创能源科技" },
        component: () => import('../components/pc/profile/Duty.vue')
      },
      {
        path: 'immp',
        name: '资质认证',
        meta: { title: "资质认证-核创能源科技" },
        component: () => import('../components/pc/profile/Immp.vue')
      },
      {
        path: 'contact',
        name: '联系我们',
        meta: { title: "联系我们-核创能源科技" },
        component: () => import('../components/pc/profile/Contact.vue')
      }
    ]
  },
  {
    path: '/product',
    name: '产品中心',
    meta: { title: "产品中心-核创能源科技" },
    component: () => import('../components/pc/Product.vue'),
  },
  {
    path: '/atlas',
    name: '概述',
    meta: { title: "多功能核数据处理软件Atlas-C-核创能源科技" },
    component: () => import('../components/pc/Atlas.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/pc/common/content/product/atlas/Index.vue')
      },
      {
        path: ':ip/:id',
        name: "详情",
        meta: { title: "软件简介-核创能源科技"},
        component: () => import('../components/pc/common/content/product/atlas/Content.vue'),
      }
    ]
  },
  {
    path: '/bamboo',
    name: '概述',
    meta: { title: "压水堆堆芯燃料管理软件Bamboo-C-核创能源科技" },
    component: () => import('../components/pc/Bamboo.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/pc/common/content/product/bamboo/Index.vue')
      },
      {
        path: ':ip/:id',
        name: "详情",
        meta: { title: "软件简介-核创能源科技"},
        component: () => import('../components/pc/common/content/product/bamboo/Content.vue'),
      }
    ]
  },
  {
    path: '/sarax',
    name: '概述',
    meta: { title: "先进反应堆物理计算分析软件SARAX-C-核创能源科技" },
    component: () => import('../components/pc/Sarax.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/pc/common/content/product/sarax/Index.vue')
      },
      {
        path: ':ip/:id',
        name: "详情",
        meta: { title: "软件简介-核创能源科技"},
        component: () => import('../components/pc/common/content/product/sarax/Content.vue'),
      }
    ]
  },
  {
    path: '/mcx',
    name: '概述',
    meta: { title: "蒙特卡罗-确定论耦合输运计算软件MCX-核创能源科技" },
    component: () => import('../components/pc/Mcx.vue'),
    children: [
      {
        path: '',
        component: () => import('../components/pc/common/content/product/mcx/Index.vue')
      },
      {
        path: ':ip/:id',
        name: "详情",
        meta: { title: "软件简介-核创能源科技"},
        component: () => import('../components/pc/common/content/product/mcx/Content.vue'),
      }
    ]
  },
  {
    path: '/Solution',
    name: '解决方案',
    meta: { title: "解决方案-核创能源科技" },
    component: () => import('../components/pc/Solution.vue'),
    children: [
      {
        path: '',
        meta: { title: "解决方案-核创能源科技" },
        component: () => import('../components/pc/solution/ContentList.vue')
      },
      {
        path: ':id',
        name: '正文',
        component: () => import('../components/pc/solution/Content.vue')
      }
    ]
  },
  {
    path: '/Notic',
    name: '通知公告',
    meta: { title: "通知公告-核创能源科技" },
    component: () => import('../components/pc/Notic.vue'),
    children: [
      {
        path: '',
        meta: { title: "通知公告-核创能源科技" },
        component: () => import('../components/pc/notic/ContentList.vue')
      },
      {
        path: ':id',
        name: '正文',
        component: () => import('../components/pc/notic/Content.vue')
      }
    ]
  },
  {
    path: '/News',
    name: '新闻中心',
    meta: { title: "新闻中心-核创能源科技" },
    component: () => import('../components/pc/News.vue'),
    children: [
      {
        path: '',
        meta: { title: "新闻中心-核创能源科技" },
        component: () => import('../components/pc/news/ContentList.vue')
      },
      {
        path: ':id',
        name: '正文',
        component: () => import('../components/pc/news/Content.vue')
      }
    ]
  },
  {
    path: '/HotNews',
    name: '热点新闻',
    meta: { title: "热点新闻-核创能源科技"},
    component: () => import('../components/pc/HotNews.vue'),
    children: [
      {
        path: '',  
        meta: { title: "热点新闻-核创能源科技" },
        component: () => import('../components/pc/hotnews/ContentList.vue')
      },
      {
        path: ':id',
        name: '正文',
        component: () => import('../components/pc/hotnews/Content.vue')
      }
    ]
  },
  {
    path: '/Recruit',
    name: '人才招聘',
    meta: { title : "人才招聘-核创能源科技" },
    component: () => import('../components/pc/Recruit.vue')
  }

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})
//解决路由跳转原路由或者刷新出错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 设置网页标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {//如果设置标题，拦截后设置标题
    document.title = to.meta.title 
  }
  next()
})

export default router
