<template>
  <router-view/>
</template>

<script>
export default {
  data () {
    return {
    
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    if (this._isMobile()) {
      // alert("手机端");
      this.$router.replace('/MIndex');
    } else {
      // alert("pc端");
      this.$router.replace('/Index');
    }
  }
}
</script>

<style lang="scss">
*{
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "Microsoft yahei";  
}
body {
  background: #f9f9f9;
}
body:after {
    clear: both;
  content: '';
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;  
}
</style>
