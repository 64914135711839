import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 首页
    bannerList: [		// 走马灯内容列表
      { 
        title: '以服务国家重大科技需求为使命，以促进产学研结合和科技成果转化为宗旨，以核能及相关领域的应用软件自主化研发和技术服务为核心业务',
        txt: '为国内外核能及相关领域提供软件研发、技术攻关、技术服务与咨询等'
      },
      { 
        title: '面向复杂的实际工程问题提供解决方案, 做到精心服务全方位掌控, 为攻克卡脖子难题做出自己的贡献',
        txt: '坚持以需求为导向，以量身定制技术为核心，以自主攻关为使命'
      },
      { 
        title: '针对核能领域的不同需求，自主研发核心产品并提供各类技术服务和咨询服务',
        txt: 'Atlas系列，Bamboo系列，SARAX系列，MCX系列等；核电厂换料校核计算、调试技术支持、运行技术改造等'
      }
    ],
    partnersList: [		// 合作单位列表
      { pic: "1", url: "http://www.spic.com.cn/" },
      { pic: "2", url: "http://www.cgnpc.com.cn/" },
      { pic: "3", url: "https://www.cnnc.com.cn/" },
      { pic: "4", url: "https://www.tsinghua.edu.cn/" },
      { pic: "5", url: "http://www.sysu.edu.cn/cn/index.htm" }
    ],
    linksList: [		// 友情链接列表
      { txt: "核工程计算物理实验室", url: "http://necp.xjtu.edu.cn/" },
      { txt: "中国核工业集团有限公司", url: "https://www.cnnc.com.cn/" },
      { txt: "中国核动力研究设计院", url: "http://www.npic.ac.cn/" },
      { txt: "中国核电工程有限公司", url: "http://www.cnpe.cc/" },
      { txt: "中国原子能科学研究院", url: "http://www.ciae.ac.cn/" },
      { txt: "中国广核集团有限公司", url: "http://www.cgnpc.com.cn/" },
      { txt: "中广核工程有限公司", url: "http://www.cnpec.com.cn/" },
      { txt: "中国工程物理研究院", url: "https://www.caep.cn/" },
      { txt: "国家电投集团科学技术研究院有限公司", url: "https://mdelxh.dlzb.com/" },
      { txt: "华龙国际核电技术有限公司", url: "http://www.hpr.com.cn/" },
      { txt: "生态环境部核与辐射安全中心", url: "http://www.chinansc.cn/" },
      { txt: "国际原子能机构", url: "https://www.iaea.org/" },
      { txt: "国际原子能机构核数据服务", url: "https://www.iaea.org/zh/zi-yuan/shu-ju-ku/he-shu-ju-fu-wu" },
    ],
    productList: [  // 产品列表
      {
        title: "多功能核数据处理软件Atlas-C",
        txt: 'Atlas-C是具有自主知识产权的多用途核数据库制作系统软件，可对多种格式（ENDF、GNDS、ENSDF等）的评价核数据库进行处理，产生多种类型的（堆用、屏蔽、活化）核数据库。经过模块功能验证，Atlas-C各处理模块结果准确，可按PENDF/GENDF格式输出结果，实现与其他同类核数据处理程序的互通；由Atlas-C加工产生的数据库经过了大量基准题验证，结果显示Atlas-C达到了与国际主流核数据处理程序相当的水平且具有一些独有的核数据处理方法及模块。',
        pic: '01',
        url: '/atlas',
      },
      {
        title: "压水堆堆芯燃料管理软件Bamboo-C",
        txt: 'Bamboo-C是西安核创能源有限公司自主研发，具有完全自主知识产权的压水堆堆芯燃料管理软件，按照软件功能分别包括：组件少群参数计算程序LOCUST、少群参数函数化程序LtoS和堆芯稳态瞬态计算程序SPARK。',
        pic: '02',
        url: '/bamboo',
      },
      {
        title: "先进反应堆物理计算分析软件SARAX-C",
        txt: 'SARAX-C(先进反应堆物理计算分析系统)程序是一套针对快谱堆芯以及加速器驱动次临界快谱堆芯的设计程序，旨在为工业界的堆芯设计以及教育机构的教学培养提供可靠的技术支持。',
        pic: '03',
        url: '/sarax',
      },
      {
        title: "蒙特卡罗-确定论耦合输运计算软件MCX",
        txt: 'MCX是西安核创能源有限公司自主研发，具有完全自主知识产权的蒙特卡罗粒子输运计算软件，通过抽样来模拟粒子在介质中的输运过程，进而求解粒子输运方程,可应用于裂变堆堆芯临界计算，各类辐射屏蔽计算及聚变包层屏蔽和增值计算。程序可进行大规模并行计算，有利于大型问题的精确计算。',
        pic: '04',
        url: '/mcx',
      },
    ],
    mcxList: [    // mcx
      {
        'treeName': "概述",
        'treeCont': [
          {
            'title': "软件简介",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'MCX采用基于Git平台的多人协同开发模式，版本管理清晰。基于CMake平台，可跨平台编译，方便地兼容Windows操作系统以及大部分Linux内核的系统。基于CTest工具，MCX可以在程序开发的同时实时地进行模块测试和基准题验证，提高了程序开发的鲁棒性。使用CI持续集成工具，保证产品的快速高质量迭代。',
                  '为了避免传统文本输入方式造成的不便，MCX采用可拓展标记语言（XML）作为输入卡片的格式规范。为了方便计算数据的后处理，MCX采用Python语言作为输出卡片格式，在兼容通用性的同时提高了数据的提取速度。MCX采用实体几何构造（CSG）建模方法，可建立带有高阶曲面的复杂几何实体，几何适用性强。MCX可读取多群中子截面数据库，进行多群形式的中子输运的特征值和固定源方程的求解。同时，MCX可以读取通用的ACE格式的连续能量数据库，进行中子输运的特征值方程求解、中子输运的固定源方程求解、光子输运的固定源方程求解以及中子-为了避免传统文本输入方式造成的不便，MCX采用可拓展标记语言（XML）作为输入卡片的格式规范。为了方便计算数据的后处理，MCX采用Python语言作为输出卡片格式，在兼容通用性的同时提高了数据的提取速度。MCX采用实体几何构造（CSG）建模方法，可建立带有高阶曲面的复杂几何实体，几何适用性强。MCX可读取多群中子截面数据库，进行多群形式的中子输运的特征值和固定源方程的求解。同时，MCX可以读取通用的ACE格式的连续能量数据库，进行中子输运的特征值方程求解、中子输运的固定源方程求解、光子输运的固定源方程求解以及中子-光子耦合输运的固定源方程求解。',
                  '蒙特卡罗方法在屏蔽问题的模拟中发挥着至关重要的作用，但是研究发现，大的几何规模、强吸收体的存在等因素会降低蒙特卡罗方法计算结果的可靠性。为了提高这类深穿透问题的计算结果的可靠性，MCX集成了基于CADIS方法和FW-CADIS方法的自动降方差技巧。为了实现这种自动降方差技巧，MCX内部耦合了SN求解软件StyxN，基于自动划分的确定论网格求解共轭方程，获得系统的权重分布，用于生成权窗网格，指导蒙特卡罗计算过程中的中子输运过程。同时，确定论求解往往需要精细网格，而蒙特卡罗计算使用的权窗网格不需要特别精细。因此MCX基于贡献因子进行网格粗化，在不显著影响计算精度、计算效率的同时，大幅度降低了计算消耗的内存。',
                  'MCX已具备输运-燃耗-活化-源项耦合计算功能，采用“程序编码式”的数据传递方式，在MCX中集成燃耗/活化/源项求解核心NECP-Erica，可进行压水堆燃耗分析、聚变堆燃耗分析以及材料辐照活化分析。MCX集成点核积分算法，利用MCX的构造实体几何和辐射源抽样方法，实现复杂问题的描述，利用点核积分和并行算法，实现辐射剂量场的快速计算。MCX也开展S&U分析方面的工作，基于反复裂变几率方法、CLUTCH方法，求解keff对连续能量核数据（截面、平均裂变中子数、裂变谱）的灵敏度系数，基于GEAR-MC方法，求解核反应率之比对连续能量核数据的灵敏度系数。MCX具备多群计算功能，可以直接读取多群宏观截面进行模拟计算，采取离散角度近似的方法处理高阶散射截面，实现对各向异性散射的处理。针对球床式气冷堆和FCM燃料问题，MCX具备随机弥散几何问题的计算能力，可以直接产生包覆燃料颗粒或燃料球的随机坐标，用户可以使用显式模拟法、弦长抽样法或扰动栅格法对随机弥散几何问题进行建模计算。针对小计数区域，MCX可以使用点探测器与环探测器对其进行计数，提高计数效率。'
                ],
                'pic': [
                  require('../assets/464978C53E0152B60439B0CE633_76C51796_272DA.png'),
                  require('../assets/94874159AE8F6622D07A2717925_B01B5413_BC3F.jpg')
                ],
                'picTxt': ''
              },
            ]
          },
          {
            'title': "联系方式",
            'date': '2023-03-24',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '地址：陕西省西安市西安市高新区锦业路69号创新商务公寓2号楼11302室',
                  '电子邮箱：Official@necphc.com;       qingming_he@xjtu.edu.cn',
                  '联系电话：029-81148661;      15002909048'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },{
            'title': "申请试用",
            'date': '2023-04-20',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '可以扫码获取MCX教育版软件。如需使用专业版，请联系贺清明15002909048。'
                ],
                'pic': [
                  require('../assets/9B1AEFDD7496404F7B2A59C9B9F_720CAC99_D331.jpg')
                ],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "理论及方法",
        'treeCont': [
          {
            'title': "核数据库",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'MCX可以直接读取多群数据库进行中子输运的模拟。目前该功能支持各项同性散射的中子输运模拟，方便进行针对方法论的分析。',
                  '蒙特卡罗方法的优势在于，可以使用连续能量的粒子与靶核作用的数据库，进行粒子与靶核作用的精确模拟，进而精确求解粒子输运方程。考虑到与其它同类蒙特卡罗程序的可对比性，MCX可采用通用的ACE格式的连续能量数据库，进行中子输运以及光子输运的模拟。',
                  '在除不可辨共振区以外的能量段，MCX采用插值的方式获得对应入射能量的粒子作用截面。对于不可辨共振区，MCX采用概率表抽样的方式获得该区域的截面。对于某些特殊化合物中的核素，如水中氢，MCX可以读取热散射截面进行热散射修正。对于作用之后的粒子出射能量以及角度，MCX根据ACE格式的连续能量数据库中存储的数据进行随机抽样，实现了粒子与靶核作用的精确模拟。'
                ],
                'pic': [
                  require('../assets/2F05B9DAF3F0FFB1664086761A3_C7B930E2_DCC9.jpg')
                ],
                'picTxt': '图1 ACE格式数据库中U238总截面随能量变化的曲线'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/3665B017E9D290980B77C314F81_70C86FAF_1DA70.png')
                ],
                'picTxt': '图2 不可辨共振的概率表处理方式示意图'
              }
            ]
          },
          {
            'title': "中子输运过程",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '基于通用的ACE格式的连续能量数据库，MCX可进行中子输运的精细模拟。对于中子散射过程，MCX基于数据库中存储的分布法则以及分布数据，随机抽样出射中子的能量以及出射角度。对于中子吸收反应，MCX提供了两种处理方式：直接模拟方式；隐俘获方式。对于中子裂变反应，MCX基于无偏抽样的原则，充分利用中子碰撞的位置信息，降低了裂变中子产生的随机性，提高了计算效率。',
                  '目前，MCX可以进行求解中子的特征值问题、固定源问题以及次临界系统下的带裂变介质的固定源问题。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "光子输运过程",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '基于连续能量的光子与靶核的光原子数据库，MCX可进行光子输运的固定源求解。若同时使用连续能量的中子数据库中存储的中子作用产生光子的信息，MCX可进行中子-光子耦合的固定源求解。',
                  '所谓光原子反应，即为光子与靶核的核外电子发生的作用过程。光子与靶核的光原子反应可分为四类：相干散射、非相干散射、电子对效应以及光电效应。对于相干散射，MCX采用原子形状因子修正自由电子的相干散射截面。对于非相干散射，MCX采用康普顿轮廓数据修正Klein-Nishina截面，并且通过多普勒展宽模型修正出射光子的能量。',
                  '对于非相干散射、电子对效应以及光电效应产生的带电粒子，MCX提供了一种基于厚靶韧致辐射模型的处理方式。该处理方式可以避免带电粒子的真实模拟，快速模拟带电粒子产生光子的过程，在不降低计算效率的同时获得更为真实的光子通量分布。'
                ],
                'pic': [
                  require('../assets/991D50FC612919620AD40C7A3C5_70A78F30_F25C.png')
                ],
                'picTxt': '光原子反应的四种类型'
              }
            ]
          },
          {
            'title': "传统降方差技巧",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在现实世界中，粒子的游走和碰撞过程随机性非常强，而计算机的计算能力限制了模拟的粒子总数，这个矛盾会导致模拟结果的方差很大，模拟结果的可靠性很低。所以，一般的蒙特卡罗程序都会采用一些经典的降方差技巧，降低粒子游走和碰撞过程的随机性，从而降低模拟结果的统计方差，提高模拟结果的可靠性。MCX中有以下几种经典的降方差技巧。',
                  '1.隐俘获。在现实世界中，中子和靶核发生碰撞时，要么发生散射反应，要么发生吸收反应。这两类反应在现实世界中是不会同时发生在一个中子的某一次碰撞上的。但是，如果有大量中子同时和靶核发生碰撞，则吸收反应造成的中子个数的减少是符合统计规律的，是一个确定的物理量。因此，MCX可以采用隐俘获的思想处理中子的吸收反应。在中子和靶核发生碰撞时，只发生散射反应，不发生吸收反应。吸收反应的模拟体现在，扣除当前模拟的中子的部分权重，以微观上的当前中子权重的降低体现宏观上的中子个数减少的物理规律。',
                  '2.区域重要性。在求解中子输运方程的时候，用户往往更关注某一些区域内的中子通量，因此往往需要提高特定区域的中子通量的结果的可靠性。由统计学原理可知，提高统计值的可靠性的一个重要方法是增加样本量，即在特定区域内进行更多的模拟。MCX采用区域重要性来增加特定区域的样本量。重要性越高体现了用户越关心该区域的中子通量，而重要性低体现了用户越不在意该区域的中子通量。当中子从重要性低的区域运动到重要性高的区域时，中子会分裂成若干个中子，但是分裂之后的中子的权重会降低，保证进入该区域的总权重不变。而当中子从重要性高的区域运动到重要性低的区域时，中子会进行轮盘赌，减少模拟的中子的个数，但是存活下来的中子的权重会提高，保证进入该区域的总权重不变。',
                  '3.权窗。权窗的原理与区域重要性类似，但是存在一定的差异。区域重要性往往是用于空间上的降方差，而权窗可用于空间、能量、时间等相空间。定义权窗需要三个参数，权窗上限、存活权重以及权窗下限。当中子进入相空间内的权窗时，首先需要根据当前中子的权重、权窗上限以及权窗下限进行判断。若当前中子的权重低于权窗下限，则进行轮盘赌，减少模拟的中子个数，但提高存活的中子权重至存活权重；若当前中子的权重高于权窗上限，则进行分裂，增加模拟的中子个数，但降低每一个中子的权重至存活权重。',
                  '区域重要性和权窗的设置需要用户手动输入，其降方差的效果依赖于用户的经验。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "离散纵标方法",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '离散纵标法（SN方法）是求解中子输运方程的一类确定论方法，其特色为将角度方向按一定求积组方向离散，解决输运方程中角度自变量的离散化问题，而空间上依然可以选取多种离散方法，如差分法，节块法或者有限元方法。能量上使用确定论方法通用的分群方法，配合多群截面进行计算。综上，离散纵标法将拥有6个自变量的连续的稳态中子输运方程离散成一个大的方程组。通过经典数值方法，对该方程组进行矩阵迭代即可完成求解，求解的输出量则为在空间、能量和角度上离散后的通量分布。MCX中的SN求解器采用自主开发的大规模并行SN计算软件StyxN。该软件经过广泛验证，具有较高的计算效率和可信度。同时支持基于区域分解的大规模并行。可以在耦合方法中充分发挥SN方法的优势。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "自动降方差技巧",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在粒子输运问题中，共轭方程求得的共轭通量代表了该系统中的相空间位置对于响应量的重要性大小。在蒙特卡罗模拟中，可以将共轭通量作为重要性参数来设置权窗以进行重要性抽样。在重要性高的相空间区域，设置较低的权窗，促使分裂更多的粒子，以进行更充分的模拟；反之，在重要性低的相空间区域，设置较高的权窗，促使粒子进行赌，使一部分粒子终止模拟，避免在不重要的区域浪费模拟时间。这样，能够根据重要性，有选择地控制粒子数目，从而达到增加目标计数位置的样本数目的目的。这样提高了目标计数的准确性，解决了蒙特卡罗计算的高耗时和深穿透的问题。由于蒙特卡罗方法直接求解共轭问题依然耗时巨大且有深穿透等蒙特卡罗固有问题，考虑到共轭计算只需给出大致的重要性分布，并不需要精细的分布值，所以使用相对粗略的确定论计算共轭方程，可以快速实现上述目的。这种以共轭通量为权窗参数的思想即为CADIS方法和FW-CADIS方法的基础。',
                  '为了解决大规模屏蔽问题中的小概率深穿透现象，MCX开发了基于CADIS方法和FW-CADIS方法的自动降方差功能，耦合的确定论程序为自主研发的NECP-Hydra。MCX实现了该功能的自动建模，保证了确定论计算和蒙特卡罗计算使用的几何模型的一致性，具有广泛的适用性和通用性。采用内耦合的策略，避免通过文本形式传输大量数据，提高了耦合的数据传递效率。',
                  '该功能的计算框架为：',
                  '1.根据输入卡片建立蒙特卡罗计算模型，根据该模型划分确定论程序StyxN计算所需的精细网格；',
                  '2.根据用户的输入，设置共轭源，将该共轭源作为输入传递给StyxN进行共轭中子通量的求解；',
                  '3.将求解获得的共轭中子通量作为权窗参数，传递给MCX，指导MCX计算过程中的中子输运过程，提高MCX的计算效率。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "基于贡献因子的网格自适应方法",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在将自动降方差功能应用于大型几何模型的计算时，会出现内存问题。基于CADIS流程的耦合方法中，权窗网格默认与SN计算网格相同。一般计算大规模问题时SN计算的网格数目都比较多。在权窗定义中，每个网格需要存储权窗上界，权窗下界和存活权重。这样用于存储权窗参数的内存消耗会很大。在SN计算中，可以通过区域分解来解决内存限制的问题。总内存保持不变，核数越多越不容易遇到计算瓶颈。而在蒙特卡罗模拟中，基于粒子的并行需要将所有模型信息复制到并行中的各个核上，其中也包括权窗参数。随着大规模并行中核数的增长，每个节点上内存有限，容易碰到内存瓶颈，影响耦合方法对于问题规模的适应性。',
                  '考虑到重要性参数主要用于蒙特卡罗模拟中的重要性抽样，其作用主要是控制粒子数目，在有些对源-探测器系统贡献小的区域，并不需要太精细的重要性参数分布。所以，适当的网格粗化并不会影响重要性抽样的效果，也就不会影响整体蒙特卡罗模拟的计算精度和效率。因此，MCX开发了一套结合贡献因子理论的基于嵌套网格的权窗网格自适应方法。该方法采用嵌套网格的数据结构，利用贡献因子的大小，对StyxN使用的精细网格进行自适应变化，最终产生MCX使用的权窗网格。',
                  '结合自动降方差技巧功能，网格粗化的计算框架为：',
                  '1.根据输入卡片建立蒙特卡罗计算模型，根据该模型划分确定论程序StyxN计算所需的精细网格；',
                  '2.根据用户的输入，设置共轭源，将该共轭源作为输入传递给StyxN进行共轭中子通量的求解；',
                  '3.采用StyxN进行中子输运方程求解，获得前项通量分布以及贡献因子分布；',
                  '4.根据贡献因子大小以及贡献因子守恒原则，对精细网格进行自适应变化，直至达到用户要求的网格数目为止，获得自适应变化后的权窗网格参数；',
                  '5.将产生的权窗网格参数，传递给MCX，指导MCX。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "输运-燃耗/活化耦合方法",
            'date': '',
            'content': [
              { 
                'subTitle': '蒙特卡罗-确定论耦合方法',
                'txt': [
                  '在受到中子的辐照后，材料中的原子核组分会发生改变。对于压水堆中的燃料，这个过程会消耗其中的235U，从而降低压水堆的增殖因子。对于聚变堆中的燃料，这个过程会消耗其中的6Li，从而降低聚变堆的氚增殖比（TBR）。对于两类堆芯使用的结构材料，这个过程会产生活化核素，进而导致辐射剂量的上升。',
                  '针对上述的燃耗/活化过程，MCX耦合了自主开发的燃耗/活化方程求解模块Erica，实现了蒙特卡罗中子学计算-燃耗/活化计算的耦合求解。该耦合平台采用内耦合策略，避免了文件读写，提高了耦合计算的效率。',
                  '在实际运行过程中，某些堆芯在一段时间内会出现功率分布剧烈变化的情况。为了准确地模拟这种情况下的堆芯燃耗/活化过程，MCX对中子学计算获得的 反应率进行直接插值，实现了高阶的中子学-燃耗/活化耦合策略。相对于传统的针对原子核密度插值的耦合策略，该耦合策略具有更高的自洽性，同时可通过提高插值的阶数获得更精确的解。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "点核积分",
            'date': '2021-07-03',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '点核积分方法是一种用于快速计算粒子辐射场的半经验性的解析计算方法，适合计算和处理复杂几何空间的辐射问题。点核积分方法中，辐射源经过空间和能量离散后可以视为大量离散点源的集合。辐射源在空间中某一位置处的辐射效应可以视为所有点源在该点处的辐射效应的积分值。由于点核积分方法本身只计算直穿粒子对空间内某一位置的辐射贡献，因此需要额外引入积累因子来修正粒子的散射效应，使窄束辐射的衰减规律可应用于宽束辐射。',
                  '相比蒙特卡罗方法和离散纵标方法，点核积分方法具有更快的计算速度，且不受问题空间尺寸和屏蔽体厚度的限制，但同时由于无法准确考虑粒子的散射效应，计算结果通常偏保守，精度低于前两种方法。点核积分方法在辐射场计算方法有较大的优势，通常用于γ剂量的快速计算。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "S&U",
            'date': '2021-07-03',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在MCX中，基于微扰理论得到keff对核数据的灵敏度系数公式，基于反复裂变几率方法获得共轭通量，该理论将共轭通量，即中子价值解释为，在相空间中的某一点引入一个中子，该中子在若干代之后产生的裂变中子数，因此可以在蒙卡前向模拟中求得共轭通量，无需进行共轭计算，进而求得keff对连续能量核数据的灵敏度系数。针对反复裂变几率方法存在的计算效率低、内存占用大的问题，分别采用重叠块法、稀疏矩阵存储方法来解决。'
                ],
                'pic': [
                  require('../assets/C53FDF3C4DEDC65FC357DAFB06B_05EE9124_8F54.jpg')
                ],
                'picTxt': '反复裂变几率原理示意图'
              },
              { 
                'subTitle': '',
                'txt': [
                  '当前MCX支持敏感性分析的核数据有：截面、平均裂变中子数、裂变谱。支持的响应参数有：keff。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "多群功能",
            'date': '2021-07-03',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '为方便与确定论方法进行对比和耦合计算，在MCX中开发了多群计算功能。将蒙卡方法应用于多群输运计算时，通常将中子散射角分布按有限阶数的勒让德多项式展开，以近似考虑系统内中子散射的各向异性。但是，当展开阶数较小时，该近似角分布在其定义域内可能出负。针对该现象，对于P1近似（即对中子散射角分布进行一阶展开），MCX通过保留一阶矩的方式将展开式重新定义为阶梯函数，避免出负。对于其它阶，MCX采取了基于广义高斯分布的离散角近似方法，避免了负的角分布。目前程序最高支持到P9近似。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "软件使用",
        'treeCont': [
          {
            'title': "输入输出",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/87588CCAE783ACA58229E963156_F6126B73_A054.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  'MCX采用XML语言作为输入卡片格式，同时支持多种输入方式，其中包括：',
                  '1.直接在XML格式的输入卡片中定义求解问题的几何、材料等信息，作为MCX的输入；',
                  '2.在XML格式的输入卡片中，指定已有的MCNP输入卡片的路径和名称，MCX可直接读取已有的MCNP输入卡片中的几何、材料等信息，进行问题的求解；',
                  '3.将CAD模型导入SALOME可视化建模平台，基于该平台可自动生成MCNP输入卡片，之后通过方式2即可实现可视化三维模型的自动建模；',
                  '4.用户可通过网页客户端设置求解问题的参数，并可以在远程后台上自动生成方式1的XML格式的输入卡片。',
                  'MCX的输出可分为以下几类：',
                  '1.Python脚本输出以及计算结果的二进制文件。该类输出文档主要包括MCX计算的数值结果、MCX计算使用的模型信息、用户输入的参数的回显等。',
                  '2.PPM输出。该类输出文档主要用于生成计算模型的剖面图，即前处理的JPG图片。使用提供的脚本，即可实现PPM格式到JPG格式的转换，从而实现计算模型的可视化。',
                  '3.VTK输出。该类输出文档主要用于计算结果的可视化。通用的可视化软件，如VISIT和ParaView，均可以打开该类文档，主要实现三维计算结果的可视化。',
                  '4.用于StyxN输入的文本文档。该类文档可以直接做为确定论程序StyxN的输入卡片，用于StyxN的计算，简化了SN程序的使用。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "使用手册",
            'date': '2021-07-04',
            'content': [
              { 
                'subTitle': '附件下载使用手册',
                'txt': [
                  '附件:',
                  'http://www.necphc.com/system/_content/download.jsp?urltype=news.DownloadAttachUrl&owner=1612398366&wbfileid=3874471'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "验证与确认",
        'treeCont': [
          {
            'title': "ICSBEP基准题",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'ICSBEP为国际临界模拟基准题库，该题库中含有大量的临界实验装置的几何描述、实验测量结果、计算使用的简化模型以及参考解。MCX计算了其中的部分算例，并将结果与MCNP进行比较。300余个算例的统计结果显示，MCX与MCNP的偏差符合正态分布规律。正态分布拟合的中心点为-13.5 pcm，这说明MCX与MCNP的系统偏差极小，符合客观规律。'
                ],
                'pic': [
                  require('../assets/824D42504D496A8A60278D0C945_7DBB8E85_10C78.jpg')
                ],
                'picTxt': 'ICSBEP基准题误差分布规律图'
              }
            ]
          },
          {
            'title': "VERA基准题",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'VERA系列基准题是CASL计划提出的适用于传统压水堆的临界基准题。目前，MCX完成了VERA#1栅元稳态基准题系列、VERA#2组件稳态基准题系列以及VERA#2组件燃耗基准题系列的计算。VERA#1稳态基准题的结果表明，MCX与国际知名蒙卡程序OpenMC的偏差小于50 pcm，与基准题提供的参考解的误差小于50pcm。VERA#2稳态基准题的结果表明，MCX与基准题提供的参考解的误差小于100pcm；VERA#2燃耗基准题的结果表明，MCX与国际知名蒙特卡罗-燃耗耦合程序Serpent计算偏差在全寿期内不超过150pcm。'
                ],
                'pic': [
                  require('../assets/0DF1A52AAC2D111D53A90469506_447A7C50_95E54A.jpg')
                ],
                'picTxt': '表1 VERA 1系列稳态问题的计算结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/BB6CCFB51F2070C8619483564B8_36EB1C24_60E136.jpg')
                ],
                'picTxt': '表2 VERA 2系列稳态问题的计算结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/409004148D7FB42E18667409B53_1D925414_7312.png')
                ],
                'picTxt': '图1 VERA 2C燃耗问题的计算结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/715399922CD3CF71032DADCF6D3_6BD7F9AC_765F.png')
                ],
                'picTxt': '图2 VERA 2P燃耗问题的计算结果'
              }
            ]
          },
          {
            'title': "HBR2基准题",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'HBR2是一个基于真实堆芯设计的屏蔽基准题。中子从一个真实的堆芯（图中的红色部分）中产生，并泄露到外部。基准题提供了压力容器（图中的绿色部分）附件的探测器响应以及生物屏蔽层（图中的粉色区域）处的探测器响应。由于计算的几何规模较大，深穿透问题较为严重，所以MCX采用了CADIS方法进行加速。',
                  '数值结果表明：',
                  '1.相比较于MCNP，MCX的并行效率在千核规模下仍保持在90%。',
                  '2.C/E在1附近，满足精度要求。',
                  '3.耦合方法相比MCNP采用权窗自动生成方法品质因子提升174倍。',
                  '4.应用网格粗化算法后，在不损失耦合方法计算精度和效率的前提下，网格数目最大可减少225倍。'
                ],
                'pic': [
                  require('../assets/2A56248F802DA475BA0A32EC22A_62ED8AA3_105B0.png')
                ],
                'picTxt': '图1 HBR-2问题的示意图'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/08EEE2CA2E13F972FECE4A4E962_C690108E_1ED52.png')
                ],
                'picTxt': '图2 并行效率的比较'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/B194A13FC4605D3391924C36A85_5B9FB89E_16DE7.png')
                ],
                'picTxt': '图3 HBR-2问题的计算结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/C6CA4ACA0F561C17061EAE9AA71_D9FAC1B9_7AB39.png')
                ],
                'picTxt': '图4 HBR-2问题的网格自适应的示意图'
              }
            ]
          },
          {
            'title': "RFNC基准题",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'RFNC基准题是SINBAD基准题库中的一套固定源的基准题，可用于验证计算程序的中子-光子耦合输运功能。中子产生于空间上某个位置，该位置被一个球体包裹住，球体的全球体部分或半球体部分填充了不同的材料。探测器放置于离源850cm处，探测中子的泄露能谱以及光子的泄露能谱。MCX完成了RFNC基准题系列的部分问题的计算，并将结果与其它蒙特卡罗程序进行了对比，结果显示，MCX与国际知名蒙特卡罗程序MCNP计算的中子能谱的结果偏差不超过1%，光子能谱的结果偏差不超过2%。此处只展示全球体填充Al问题和全球体填充Ti问题的数值结果。'
                ],
                'pic': [
                  require('../assets/FA4C387C9ABC998C6438200A4B2_39DB1CA7_13C85.png')
                ],
                'picTxt': '图 RFNC基准题的实验布置示意图'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/58A9C8D1FDC73F810796DC2BE78_5D2331E4_6E1222.jpg')
                ],
                'picTxt': '表 RFNC基准题的部分计算结果对比'
              },
            ]
          },
        ]
      },
      {
        'treeName': "发表成果",
        'treeCont': [
          {
            'title': "发表成果",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '[1].李捷，贺清明，郑琪，曹良志，吴宏春．基于NECP-MCX的CFETR包层中子学计算方法研究[C]．第一届CFER聚变能大会．乐山，2019',
                  '[2].Qi Zheng, Wei Shen, Xuesong Li, Tengfei Hao, Qingming He, Jie Li, Zhouyu Liu. A Hybrid Monte-Carlo-Deterministic Method for AP1000 Ex-core Detector Response Simulation. PHYSOR 2020: Transition to a Scalable Nuclear Future Cambridge, United Kingdom, March 29th-April 2nd, 2020',
                  '[3] 秦帅， 贺清明， 曹良志， 吴宏春. NECP-MCX中多群功能的开发与验证[C]. 第十届反应堆物理与核材料学术研讨会. 上海，2021.',
                  '[4] 黄金龙， 曹良志， 贺清明， 李捷. 连续能量核数据敏感性分析及其在AP1000中的应用[C]. 第十届反应堆物理与核材料学术研讨会. 上海，2021.'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "开发人员",
        'treeCont': [
          {
            'title': "开发人员",
            'date': '2020-08-17',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '1.贺清明',
                  '2.郑琪',
                  '3.李捷',
                  '4.时秋婕',
                  '5.黄金龙',
                  '6.包彦',
                  '7.黄展鹏',
                  '8.秦帅'
                ],
                'pic': [
                  require('../assets/BB46A94AF50D92D5202315301FD_79F8BC2D_3F89D.png')
                ],
                'picTxt': ''
              }
            ]
          }
        ]
      }
    ],
    saraxList: [    // sarax
      {
        'treeName': "概述",
        'treeCont': [
          {
            'title': "软件简介",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'SARAX-C是西安核创能源科技有限公司研发的一套自主化的先进反应堆物理计算分析程序。该程序基于确定论方法的两步法计算思想，将反应堆物理计算过程拆分为少群截面计算以及堆芯稳态计算，从而实现先进反应堆堆芯物理的设计计算。在SARAX-C中，少群截面计算采用了超细群-超超细群的计算方法，实现了高精度的能谱、有效自屏截面的计算，为堆芯提供更为可靠的少群截面参数；堆芯采用基于六角形、矩形棱柱几何的三维SN节块方法，配合粗网有限差分加速算法以及角度并行策略，并采用先进的编程语法及架构，大幅提高了中子输运方程的求解速度。SARAX-C集成了微扰反应性计算模块、燃耗计算模块，能够满足堆芯核设计的要求，计算精度与国际知名程序相当。',
                  'SARAX-C程序包括两个主要的功能模块：',
                  '1） TULIP模块，采用基于点截面的超细群方法和碰撞概率方法计算各类栅元/子组件能谱，采用SN方法进行能谱修正以考虑非均匀堆芯的区域间干涉和堆芯泄漏，实现从点截面到堆用多群截面数据库的产生；'
                ],
                'pic': [
                  require('../assets/25C3DC56B92BF4800F38214B49A_AC0EA911_36B3B.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '2）LAVENDER模块，采用基于三维棱柱几何的SN节块方法计算三维中子通量分布。采用线性子链方法计算重核素燃耗，采用Kerma因子方法计算非燃料区功率，实现堆芯的稳态分析。 '
                ],
                'pic': [
                  require('../assets/621E27C83A03D9D1F2217B7167A_ECF2C9C6_135E36.jpg')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '程序系统可实现以下功能：',
                  '1） 过程模拟：能够准确模拟实验过程和实验现象，如净堆临界装载模拟，控制棒价值测量试验模拟，反应堆冷态-热态-冷态状态变化模拟，重要的反应性反馈效应模拟，为已开展实验的“复现”提供支撑；',
                  '2） 核设计计算/校算：提供全部核设计报告及安全分析报告所要求的堆物理参数；',
                  '3） 安全分析接口：提供反应堆热工分析和安全分析的数据接口，包括：组件功率峰因子、非燃料区功率、三维反应性系数分布等；',
                  '4）燃料循环分析：提供重核素质量流在堆内的跟踪计算，并为乏燃料临界安全分析提供基础数据。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "联系方式",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '地址：陕西省西安市西安市高新区锦业路69号创新商务公寓2号楼11302室',
                  '电子邮箱：Official@necphc.com',
                  '联系电话：029-81148661'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "理论及方法",
        'treeCont': [
          {
            'title': "少群参数计算方法",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '少群截面的计算在宏观层面可以分为两部分，共振计算以及能谱计算。共振计算用以考虑核素复杂的共振效应，从而获取各核素问题相关的细群有效共振自屏截面；能谱计算则是获得该问题的细群中子通量密度分布，利用该分布对细群截面进行空间以及能量上的均匀化，最终产生计算对象的少群截面。',
                  '为了平衡计算精度与效率，共振计算将基于窄共振近似，直接使用超细群数据进行数值积分，获得均匀问题的细群有效共振自屏截面；对于非均匀几何，通过引入逃脱截面来修正窄共振近似的能谱，获得非均匀问题各区的细群有效共振自屏截面；通过预制散射函数，在线地计算各核素各阶的弹性散射矩阵。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "六角形中子输运求解器",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'SARAX-C堆芯计算程序是采用离散节块方法求解中子输运方程，其基本出发点就是把中子通量的角度分布按一些离散的方向进行求解，在空间度量上采用粗网节块和节块内的多项式展开来离散。就理论上而言，在角度上，如果考虑足够多的方向，中子输运是可以得到足够精度的解；而在空间上，采用粗网节块，不仅可以提高堆芯的计算效率，而且计算精度完全可以满足工程需要。在求解过程中，先通过横向积分技术将三维的中子输运方程转化为互相耦合的四个一维方程，并应用剩余权重法得到通量矩之间的关系式。之后采用节块等效有限差分方法。进一步提高解散节块的计算效率和减少系数的存储量。',
                  '目前，六角形中子输运求解器可以进行中子的特征值计算、固定源计算和共轭计算。'
                ],
                'pic': [
                  require('../assets/86695CF810A1528E6508EE25E34_6A4ACB4E_1A739.png')
                ],
                'picTxt': 'S4求积组的离散点示意 '
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/BDDB453B4921B95A93FA35DE0DB_0893F3E1_8DD2.jpg')
                ],
                'picTxt': '计算系下的标准六棱柱'
              }
            ]
          },
          {
            'title': "燃耗计算",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在快堆中，由于快中子的平均自由程较长，使得通量的全局耦合性很强，燃耗计算需要与全堆芯计算结合在一起，所以SARAX可对全堆芯进行燃耗计算。SARAX将全堆芯划分为若干燃耗区，在各燃耗区分别进行燃耗计算。',
                  'SARAX-C主要采用子步方法的燃耗计算策略。子步方法认为在一个燃耗步内中子通量密度的形状和堆芯总功率不变，每个子步进行燃耗方程求解后更新中子通量密度的幅值，从而得到当前子步的中子通量密度。'
                ],
                'pic': [
                  require('../assets/BA5B9B7E949CC3DBD1E5FCF7FAE_680B3809_7A42.png')
                ],
                'picTxt': '子步方法'
              },
              { 
                'subTitle': '',
                'txt': [
                  '在点燃耗方程求解方法上SARAX-C采用了线性子链方法。线性子链法整体计算主要分为两部分内容：第一、对复杂的燃耗链进行线性化；第二、分别计算每个线性链对各核素原子核密度的贡献。'
                ],
                'pic': [
                  require('../assets/94259ACEBA7D390E8CCD1888F7E_B69AD66A_2D32.png')
                ],
                'picTxt': '线性子链方法线性化过程'
              },
              { 
                'subTitle': '',
                'txt': [
                  '为了提高程序燃耗计算的精度，考虑中子在堆芯的运动导致的能量并非本地沉积，SARAX-C开发了基于KERMA因子的功率计算模块以便求解更符合实际的功率计算结果。此外，基于JNDC-v2数据库的产额数据和裂变产物的相互转化关系，SARAX-C将复杂的快堆裂变产物压缩为每种重核所对应的集总裂变产物，以便减少微观截面数据的存储。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "反应性计算",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'SARAX-C中有两种反应性计算方法：直接法和微扰理论。直接法是最为简单直观的方法，通过计算扰动发生前后的两种堆芯状态，得出有效增殖因子，从而计算出反应性引入。直接法计算反应性引入简单直观，对于反应性引入较大的情况下，由于计算不存在近似，所有计算精度很高。而对于反应性引入非常小，两种状态下计算出的有效增殖因子会非常接近的问题，',
                  '数值计算误差非常容易影响最后的计算结果，因此不便使用直接法计算出反应性引入，于是在反应性计算中引入了微扰理论。微扰理论可以很好的处理小反应性引入的计算问题，通过一次前向输运计算和共轭计算后就可以快速求解多种反应性引入，并且可以提供反应性引入贡献的空间分布，方便后续的堆芯瞬态安全分析程序进行计算。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "软件确认",
        'treeCont': [
          {
            'title': "ZPPR系列零功率实验装置",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'ZPPR-9是一个模拟圆柱形堆芯的装置，布置了两种富集度的燃料区，同时也是一个干净堆，没有任何控制棒在堆芯内部，因而将ZPPR-9作为后续试验的参考堆型。',
                  'ZPPR系列的快堆装置整体采用了板状燃料元件。随后，这些板状燃料元件被放置在一个由不锈钢制成的矩阵通道管里。这个矩阵通道的大小为4.3m*4.3m*3.0m。每一个通道里都插入一个组件盒，其内部可以是钚燃料、铀燃料、钠冷却剂、或结构材料。然后按照需求布置在这矩阵通道管里。组件的三维非均匀模型简化为一维平板模型，保留每个组件内燃料包壳壁，并把上下组件壁均匀化在这些包壳壁里。',
                  '采用了ZPPR-9试验中的临界测量试验、控制棒价值试验、钠空泡反应性试验和能谱特征测量试验作为程序确认的基准题。'
                ],
                'pic': [
                  require('../assets/FBE9F08A086E6EA309C200EF189_798C9C42_1ACB1.png')
                ],
                'picTxt': '图 ZPPR-9临界试验堆芯布置'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/D2762832E49711F88112BB74DA4_51F388DD_E98.png')
                ],
                'picTxt': '表 ZPPR-9临界试验结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/E0D487D04DB9804841D9C9ECAAC_6F75F837_2CAF.png')
                ],
                'picTxt': '表 ZPPR-9控制棒价值试验结果(1)'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/8DBA0F53AA628FF8889E7FF7AF5_FE5A5715_2C35.png')
                ],
                'picTxt': '表 ZPPR-9控制棒价值试验结果(2)'
              }
            ]
          },
          {
            'title': "JOYO系列零功率实验装置",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'JOYO是日本第一座实验快堆，并于1977年4月首次临界。JOYO初始装载着MOX燃料，燃料的轴向上和径向上都被贫铀增殖区所包围。该布置从1978年6月开始带功率运行，直到1980年12月更换整体堆芯装载方案结束。这种布置有增殖区的堆芯被称为MK-I堆芯。JOYO MK-I堆芯上进行了很多实验，公开的实验包括临界测量实验、控制棒价值测量实验、钠空泡反应性测量实验、燃料替换反应性测量实验、等温温度系数测量实验和燃耗反应性系数测量实验。'
                ],
                'pic': [
                  require('../assets/B3590C4A8FB5CA062C8C4ADCD49_2E650FF7_2050D.jpg')
                ],
                'picTxt': '图  JOYO MK-I堆芯径向布置（64盒燃料组件）'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/30EFD233335FB23F7E603DA5FC0_FAC9CA00_C630.png')
                ],
                'picTxt': '图  JOYO MK-I堆芯内组件轴向布置'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/ACDC7150E7918AE9876891AC5F5_B686B040_1D50.png')
                ],
                'picTxt': '表 JOYO MK-I堆芯临界实验计算结果的对比'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/A9431AF8F839EC6476FC64AB3F2_575D8559_2C3E.png')
                ],
                'picTxt': '表 JOYO MK-I控制棒价值测量实验的结果'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/42D10A47661177C39A948FFAD9E_22706781_2A2D.png')
                ],
                'picTxt': '表 JOYO MK-I钠空泡价值测量实验的计算结果'
              }
            ]
          },
          {
            'title': "BFS系列零功率实验装置",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'BFS-61零功率试验装置位于俄罗斯奥布宁斯克的物理与动力工程学院，设计用于快堆活性区、反射层、增殖区的全面建模，并在BFS-61零功率试验装置开展了一系列的相关实验，使用SARAX-C计算其中的部分实验，并将结果与实验值和MCNP的计算结果进行比较，计算结果表明，SARAX-C与MCNP的偏差均小于0.4%。'
                ],
                'pic': [
                  require('../assets/4773A8087EA86774ECD887224B1_D6786147_16566.jpg')
                ],
                'picTxt': '图 BFS-61装置示意图'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/AB59FF7E7F23FED400F5877CA8A_22A67A59_2234.png')
                ],
                'picTxt': 'BFS-61测量实验的计算结果'
              }
            ]
          },
          {
            'title': "CEFR启动物理试验",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '中国实验快堆（CEFR-China Experimental Fast Reactor）是中国第一座实验快堆。2010-2011年，CEFR进行了启动物理试验并发布了计算临界、控制棒价值、反应性系数、反应率的基准题。通过与实测值的对比，可以证明SARAX-C不仅对集总量keff计算准确性高，对于分布量如反应率也可以准确地模拟真实堆芯。'
                ],
                'pic': [
                  require('../assets/F4C43A8DB3838098D19DF88D4C4_46DB6797_175A.png')
                ],
                'picTxt': '图1 CEFR启动物理试验基准题-钠空泡反应性'
              },
              { 
                'subTitle': '',
                'txt': [
                  '图1展示了Na空泡反应性的计算值和测量值的对比。此试验中，将指定组件中的Na全部移除，计算反应性的变化情况。图2展示了替换反应性的计算值和测量值的对比。模拟组件装载过程中燃料组件和不锈钢反射层组件装反的情况。图3是多种反应率在轴向和径向的分布。试验中将金属箔片放入特制组件中的特定测点，由外接电路接受反应信号；在仿真中则分别计算测点处的中子通量和金属的截面，以获得总反应性。'
                ],
                'pic': [
                  require('../assets/66934BB1BB6F3153B6A2716B25F_FD71CEE6_19967.jpg')
                ],
                'picTxt': '图2 CEFR启动物理试验基准题-净堆装载'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/17024063506D5168B0EB961CB9D_5C4167D6_1CBE5.png')
                ],
                'picTxt': '图 3  CEFR启动物理试验基准题-反应率'
              }
            ]
          },
          {
            'title': "铅基临界实验装置",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '（1）HMF27铅临界试验装置临界试验',
                'txt': [
                  'HMF27基准题是一个球形的临界实验装置，主要由90%富集度的U235金属铀燃料组件以及铅反射层组成。在组件的中心部分是一个半径为1 cm的空腔，可用于存放中子源。中子源外有9层球形的裂变材料，在裂变材料外围是3.25 cm的铅反射层。'
                ],
                'pic': [
                  require('../assets/1C6B88623C190DC12127903B5F2_B4D2336E_F395.png')
                ],
                'picTxt': 'HMF27组件的剖面示意图'
              },
              { 
                'subTitle': '（2）PMF35铅临界试验装置临界试验',
                'txt': [
                  'PMF35基准题是一个球形的临界实验装置，主要由98%富集度的δ相的Pu239金属钚燃料组件以及铅反射层组成。装置是由半径为1.2厘米的中心空腔、厚度为4.8厘米的富集度为98%的Pu239燃料和厚度为3.15厘米的铅反射层的球壳组成，其中燃料区分为五层。'
                ],
                'pic': [
                  require('../assets/9BE90D2F71B1A86937DE8C8C47D_4E52186E_10B97.png')
                ],
                'picTxt': 'PMF35组件的剖面示意图'
              },
              { 
                'subTitle': '（3）HMF57铅临界试验装置临界试验',
                'txt': [
                  'HMF57基准题是两个球形的临界实验装置，主要是以93.17%富集度的U235为燃料以及铅反射层组成。模型1是由半径为0.5555厘米的PoBe中子源、厚度为6.555厘米的U235燃料和厚度为17.22厘米的铅反射层的球壳组成的总半径为24.3305厘米的球形装置。模型2是由半径为0.5555厘米的PoBe中子源、厚度为6.9235厘米的U235燃料和厚度为8.99厘米的铅反射层的球壳组成的总半径为16.469厘米的球形装置。'
                ],
                'pic': [
                  require('../assets/85BC2C3EC60A9A12B8ED88F183B_79B1E0D6_69D6.png')
                ],
                'picTxt': 'HMF57组件的剖面示意图'
              },
              { 
                'subTitle': '（4）数值结果',
                'txt': [
                  'HMF27和PMF35铅冷临界实验装置的有效增殖因子keff的计算结果如表1所示。HMF57铅冷临界实验装置的有效增殖因子keff的计算结果如表2所示。表中除了实验值、SARAX-C程序计算的结果、MCNP程序计算的结果之外，还有俄罗斯使用蒙特卡洛程序C-90结合BAS数据库计算的结果，美国使用KENO程序结合16群Hansen-Roach数据库计算的结果，美国使用MCNP程序结合ENDF/B-V和ENDF/B-Ⅶ β3的连续能量数据库计算的结果，俄罗斯使用KENO程序结合299group ABBN-93数据库计算的结果。',
                  '可以看到，除HMF57-1的结果与实验值误差较大外，其他基准题中SARAX-C程序计算结果和实验值符合的很好，误差在300pcm以内，和其他程序的计算蒙卡程序的计算结果相比也可以接受。在HMF57-1中可以发现，ENDF/B-Ⅶ的系列数据库与实验值的误差均在1000 pcm左右，相同数据库下的SARAX-C计算结果与蒙卡结果很吻合，误差仅为98pcm。'
                ],
                'pic': [
                  require('../assets/AE85D388193FB81CEA25DAB32C4_8BDB47C2_3B30.png')
                ],
                'picTxt': '表 HMF27 和PMF35铅冷临界实验装置keff计算结果对比'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/0E0FC1F771983A9555B3AF6D114_51A56C09_33AE.png')
                ],
                'picTxt': '表 HMF57铅冷临界实验装置keff计算结果对比'
              }
            ]
          }
        ]
      }
    ],
    bambooList: [   // bamboo
      {
        'treeName': "概述",
        'treeCont': [
          {
            'title': "软件简介",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '为解决我国压水堆核电厂工业应用和新一代核电技术改进的关键问题，西安核创能源科技有限公司研发了具有完全自主知识产权的、先进压水堆燃料管理软件LOCUST/SPARK。LOCUST/SPARK软件基于经典的“两步法”工业模拟流程研发，采用目前最先进的核反应堆物理的理论方法，具备完善的分析功能、高效的计算效率和便捷的用户使用的特点，能够切实满足压水堆核电厂工业应用的需求。',
                  '截止目前，LOCUST/SPARK软件已经基于近100堆年的压水堆运行历史数据上完成了软件的工业确认，确认对象涵盖了我国商用压水堆的主要堆型，包括CNP300/650/1000、CPR1000、AP1000等，并且完成了“华龙一号”两个型号共8个燃料循环的核设计校算工作。工业确认结果表明，LOCUST/SPARK软件对于不同压水堆堆型、不同堆芯关键物理量的计算精度均极好地满足了工业限值要求，达到了压水堆核电厂工业应用对计算精度和分析功能的需求。'
                ],
                'pic': [
                  require('../assets/794E8DCA6BD0ED78E972C65706D_A8CC63E1_9A083.png')
                ],
                'picTxt': 'LOCUST/SPARK软件著作权'
              },
              { 
                'subTitle': '',
                'txt': [
                  'LOCUST/SPARK软件由三个功能程序组成：组件程序LOCUST（Lattice code for nuclear-fuel modeling and simulation），堆芯程序SPARK（System for reactor-physics steady state and transient kinetics analysis）和函数化程序LtoS（LOCUST to SPARK linker），软件的整体结构如下图所示。'
                ],
                'pic': [
                  require('../assets/E909AB5E5F15D98BBED96730E8E_13F7E80D_29912.png')
                ],
                'picTxt': 'LOCUST/SPARK软件整体结构'
              }
            ]
          },
          {
            'title': "联系方式",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '地址：陕西省西安市高新区锦业路69号创新商务公寓2号楼11302室',
                  '电话：029-81148661',
                  '邮箱：Official@necphc.com'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "理论及方法",
        'treeCont': [
          {
            'title': "组件程序-LOCUST",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '组件程序LOCUST用于燃料组件和径向/轴向反射层组件的非均匀中子输运和组件均匀化参数的计算，其主要的组成部分和功能模块包括：基于ENDF/B-VII.0评价核数据库制作的69群NECL格式的多群核数据库和连续能量截面数据库，以及包含233个核素的燃耗数据库（9个重金属核素、195个裂变产物核素和9个活化核素），上述核数据库均由自主研发的核数据处理软件Atlas-C制作，能够非常方便地根据用户需求对核数据库中的核素进行扩展；采用先进的Global-Local方法用于截面共振处理，有效地处理多核素共振干涉现象，提高有效自屏截面的计算精度；采用模块化MOC方法用于非均匀中子输运方程求解；采用高效的CRAM方法用于点燃耗方程的求解，并且采用裂变释能“On-the-Fly”的计算方法，更精确地计算裂变能量沉积；采用高阶的中子输运-燃耗耦合策略，显著提高组件燃耗计算的精度；采用等效均匀化理论，获得组件层面的少群（两群）均匀化参数、Pin功率分布形状因子、动力学参数等。LOCUST程序具备自动化的燃料温度、慢化剂温度、硼浓度和控制棒等工况参数的重启动功能，能够根据用户需求产生组件均匀化参数关于不同工况参数的列表数据，为均匀化参数函数化提供必备的数据库。',
                  '采用上述先进的计算方法，LOCUST程序适用于不同类型的燃料组件（MOX和UOX）、不同类型的可燃毒物（Pyrex、Gd2O3、IFBAR、WABA等）、不同类型的控制棒吸收体（AIC、B4C、不锈钢和钨等）。需要强调的是，LOCUST程序除了在理论方法方面比早期开发的组件程序具有先进性之外，在燃料组件格架和反射层组件的几何材料建模方面也具有精细化和自动化的特色。'
                ],
                'pic': [],
                'picTxt': ''
              },
              { 
                'subTitle': '燃料组件轴向格架自动化、精细化几何材料建模',
                'txt': [
                  'LOCUST程序能够根据用户定义的内外条带的厚度和材料等信息，对燃料组件轴向的定位格架进行自动化、精细化的几何材料建模，从而更加精确地考虑燃料组件在轴向格架位置处的中子吸收增强的现象，以获得更加精确地组件燃耗分布和堆芯轴向功率偏移的计算结果。'
                ],
                'pic': [
                  require('../assets/BD5B5EBF86C52981A0C4A9BA4F1_75A0060E_9493.png')
                ],
                'picTxt': '燃料组件轴向格架几何材料建模'
              },
              { 
                'subTitle': '径向反射层组件自动化、精细化几何材料建模',
                'txt': [
                  'LOCUST程序能够根据用户定义的围板厚度、围板水隙、吊篮内/外径、热屏内/外径和位置/角度、压力容器内/外径等几何和材料信息，自动化地生成不同位置处、多个径向反射层的精细几何和材料的建模信息。相比于早期开发的组件程序，LOCUST程序对径向反射层的几何材料精细化建模方法不依赖专家经验，在保障堆芯径向反射层真实几何材料信息的基础上，极大地保障了建模的便捷性。'
                ],
                'pic': [
                  require('../assets/4B3C43F223419A3A01AF76618CA_03C98D0D_7926D.png')
                ],
                'picTxt': '径向反射层几何材料建模'
              }
            ]
          },
          {
            'title': "函数化程序–LtoS",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '函数化程序LtoS根据LOCUST程序产生的燃料组件和径向/轴向反射层组件均匀化参数关于燃耗和工况参数（包括燃料温度、慢化剂温度、硼浓度、控制棒等）的列表数据，实现对少群均匀化参数的函数化。在LtoS程序中：对于燃耗变量，采用线性插值方法；对于工况参数，采用最小二乘拟合方法或多维拉格朗日插值方法。作为输出，LtoS程序输出不同的均匀化参数的函数化系数，为堆芯程序SPARK提供高效率、高精度的均匀化参数反馈结果。'
                ],
                'pic': [
                  require('../assets/90ADA71674DC7288A3904E4E8C2_638FCF81_4943.png')
                ],
                'picTxt': 'LtoS程序对燃耗和工况参数的函数化方法'
              }
            ]
          },
          {
            'title': "堆芯程序–SPARK",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '堆芯程序SPARK用于三维堆芯稳态和瞬态建模模拟，其主要的功能模块包括：采用高效的非线性迭代两节块方法用于堆芯三维中子扩散方程求解；采用半隐式θ方法用于时间变量离散，实现瞬态数值求解；采用并联多通道的热工参数反馈计算，相比单通道热工计算具有更高的精度；采用微观/宏观燃耗相结合的堆芯燃耗分布计算方法，重点跟踪29个重金属核素在燃料循环过程中的转换和消耗；采用中子学-热工-燃耗紧耦合策略，提高堆芯计算的稳定性；采用基于中子共轭通量的空间响应函数方法，计算堆外探测器响应率等。'
                ],
                'pic': [
                  require('../assets/B0965CD74729363E3C23C3224D5_BAAE2353_B0DD.jpg')
                ],
                'picTxt': 'SPARK程序关键重金属核素燃耗链'
              },
              { 
                'subTitle': '',
                'txt': [
                  'SPARK程序采用分类的形式实现对不同堆芯关键物理量的计算，主要包括：#StartUp用于堆芯启动物理试验相关物理量计算，包括临界硼浓度、硼微分价值、慢化剂/等温温度系数、控制棒积分价值、组件功率分布等；#Depletion用于堆芯功率运行跟踪相关物理量计算，包括临界硼浓度、功率分布、燃耗分布、轴向功率偏移AO、功率峰因子（Fq/FdH/Fxy）、温度分布等；#Calibrate用于控制棒微积分价值相关物理量的计算，包括：控制棒微分价值、积分价值、临界棒位、控制棒咬量等；#Perturb用于堆芯功率和温度相关系数的计算，包括功率系数、慢化剂温度/功率系数、多普勒温度/功率系数等；#Transient用于堆芯事故瞬态计算，包括弹棒和落棒事故下堆芯功率水平、反应性、温度等随时间的变化规律等。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "工业确认及应用",
        'treeCont': [
          {
            'title': "CNP300堆型工业确认",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '已完成CNP300堆型的某压水堆核电厂U1机组共11个燃料循环的工业确认，确认结果如图 7和图 8所示。工业确认结果表明：对于临界硼浓度，96.3%的误差均满足工业限值要求，且误差的均值为-12.7ppm、RMS值为21.6ppm；对于相对功率大于0.9的组件功率分布，99.7%的误差均满足工业限值要求，且相对误差的均值为-0.5%、RMS值为1.2%；对于功率小于0.9的组件功率分布，100%的误差均满足工业限值要求，且相对误差的均值为3.0%、RMS值为3.8%。'
                ],
                'pic': [
                  require('../assets/DACE87EDC366ACDA6844857E8E3_2C809204_D741.jpg')
                ],
                'picTxt': 'CNP300临界硼浓度误差及其统计结果'
              },
              { 
                'subTitle': '',
                'txt': [
                
                ],
                'pic': [
                  require('../assets/174C11AA3BBB0ECD221E8C21FD3_32AB5CC2_9510.jpg')
                ],
                'picTxt': 'CNP300功率分布误差统计结果'
              }
            ]
          },
          {
            'title': "CNP650堆型工业确认",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '已完成CNP650堆型的某压水堆核电厂U1和U2机组共9个燃料循环的工业确认，确认结果如所示。工业确认结果表明：对于临界硼浓度，99.9%的误差均满足工业限值要求，且误差的均值为-10.3ppm、RMS值为20.4ppm；对于相对功率大于0.9的组件功率分布，100%的误差均满足工业限值要求，且相对误差的均值为-0.3%、RMS值为1.6%；对于功率小于0.9的组件功率分布，100%的误差均满足工业限值要求，且相对误差的均值为2.0%、RMS值为3.0%。'
                ],
                'pic': [
                  require('../assets/2590F216CFC7D5A1C277DEABC86_EB2AC6DF_CE79.jpg')
                ],
                'picTxt': 'CNP650临界硼浓度误差及其统计结果'
              },
              { 
                'subTitle': '',
                'txt': [
                
                ],
                'pic': [
                  require('../assets/0D1C35E04AB345E420FA8C65885_90049F41_ABF9.jpg')
                ],
                'picTxt': 'CNP650功率分布误差统计结果'
              }
            ]
          },
          {
            'title': "CNP1000堆型工业确认",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '已完成CNP1000堆型的某压水堆核电厂U1-U4机组共13个燃料循环的工业确认，确认结果如所示。工业确认结果表明：对于临界硼浓度，100%的误差均满足工业限值要求，且误差的均值为2.4ppm、RMS值为17.9ppm；对于相对功率大于0.9的组件功率分布，99.9%的误差均满足工业限值要求，且相对误差的均值为-0.6%、RMS值为1.6%；对于功率小于0.9的组件功率分布，99.9%的误差均满足工业限值要求，且相对误差的均值为0.3%、RMS值为2.2%。'
                ],
                'pic': [
                  require('../assets/7C4F974BA05E14A3C246E7485EF_D57E628B_E460.jpg')
                ],
                'picTxt': 'CNP1000临界硼浓度误差及其统计结果'
              },
              { 
                'subTitle': '',
                'txt': [
                
                ],
                'pic': [
                  require('../assets/DA1492B53C7B39A1D386C394509_8B5B5AD4_CE4B.jpg')
                ],
                'picTxt': 'CNP1000功率分布误差统计结果'
              }
            ]
          }
        ]
      }
    ],
    atlasList: [  // atlas
      {
        'treeName': "概述",
        'treeCont': [
          {
            'title': "软件简介",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'Atlas-C是具有自主知识产权的多用途核数据库制作系统软件，可对多种格式（ENDF、GNDS、ENSDF等的评价核数据库进行处理，产生多种类型的（堆用、屏蔽、活化）核数据库。经过模块功能验证，Atlas-C各处理模块结果准确，可按PENDF/GENDF格式输出结果，实现与其他同类核数据处理程序的互通；由Atlas-C加工产生的数据库经过了大量基准题验证，结果显示Atlas-C达到了与国际主流核数据处理程序相当的水平且具有一些独有的核数据处理方法及模块。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "联系方式",
            'date': '',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  ''
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      {
        'treeName': "理论及方法",
        'treeCont': [
          {
            'title': "共振重构及线性化模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '共振重构与线性化模块主要功能是将核素的ENDF中给出的截面相关数据转换为可线性插值的能量-截面插值表，即温度为0K时的连续能量数据。连续能量数据对于数据处理以及后续的中子学程序计算具有重要意义：',
                  '1. 线性化的截面数据便于插值与积分；',
                  '2. 多普勒展宽模块需要可线性插值的截面数据；',
                  '3. 线性化的截面数据可以被连续能量的蒙卡程序直接使用。',
                  '本节将简要介绍共振重构与线性化模块的数据处理方法。首先给出U-238截面随能量的变化图。如图1所示'
                ],
                'pic': [
                  require('../assets/18D70A46CECE45106649E264A26_CF9432C3_DA74.jpg')
                ],
                'picTxt': '图1 U-238总截面示意图'
              },
              { 
                'subTitle': '',
                'txt': [
                  '根据截面随能量变化趋势，可将其分为三个区域，即非共振区，可辨共振区域以及不可辨区域。在非共振区，截面随能量的变化可以看做是光滑的曲线；在可辨共振区有许多高窄的共振峰，这些共振峰的相关参数数据可由实验测得；而在不可辨共振区则存在更多更密集的共振峰，这些共振峰的相关参数数据无法由实验测得。因此ENDF中对于共振区与非共振区的数据采用不同的方式进行数据存储。对于可辨共振区域，ENDF中的数据是按照共振模型进行存储。每个核素ENDF均给出了相应的共振模型，并存储了不同共振模型对应的共振参数，如共振能量，能级宽度，共振峰截面等；对于不可辨共振区，ENDF则给出共振参数以及其分布的概率；对于非共振区域，ENDF按照插值表的形式进行数据存储。ENDF提供了相邻两能量点的能量值与截面值以及两能量点间的插值方式。因此，共振重构与线性化模块的数据处理过程主要分为三部分。首先对于可辨共振区域，截面重构模块将ENDF中存储的共振参数，通过对应的共振计算公式重构得出共振截面；对于不可辨共振区，截面重构模块通过ENDF中存储的不可辨共振参数重构出无限稀释背景截面下的不可辨共振截面；对于非共振区域，截面重构模块是将非线性插值的截面重构计算为线性插值的截面。',
                  '对各个不同的反应道进行截面重构后，为了使截面自洽，需要对所有反应道的截面进行加和处理，并需将所有反应道的能量网格进行统一，最后将各个反应道的能量-截面插值表以PENDF(Point-wise ENDF)格式进行输出。该模块的程序流程图如下图所示：'
                ],
                'pic': [
                  require('../assets/972BFCC89692A24ADCE731C10D8_BF5C89F3_1D6E.png')
                ],
                'picTxt': '图2 共振重构与线性化模块计算流程示意图'
              }
            ]
          },
          {
            'title': "共振弹性散射核计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '中子和靶核发生反应的截面仅与二者的相对速度相关，即无论环境温度是多少，只要二者的相对速度相同，则反应截面相同，评价核数据库中中子的能量即为相对系下的能量，而中子输运方程中中子能量为实验室坐标系下的能量，在实验室坐标系中，固定能量的中子与靶核碰撞时，由于不同温度下靶核运动状态不同，所以二者相对运动的速度不同，造成实验室坐标系中不同温度下中子和靶核反应的截面不同，因此，需通过相对坐标系下的反应率和实验室坐标系下的反应率守恒，将评价库中的截面转化为实验室坐标系下不同温度的截面，即进行多普勒展宽，这种靶核热运动对实验室坐标系下截面产生影响的效应成为多普勒效应。',
                  '基于半解析积分方法求解微分截面矩并产生S(α,β,T)插值表，S(α,β,T)插值表的入射能量网格基于采用多点线性化产生，能与目标核素和温度相适应，可以精确的反映靶核热运动和共振弹性散射截面对出射中子能量角度分布的影响。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
          {
            'title': "多普勒展宽模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '在相对参考坐标系内入射粒子与靶核的反应截面与温度无关，然后在输运程序中使用的波尔兹曼方程是以实验室参考坐标系建立的，并非以中子和靶核的相对参考坐标系为参考，只有在靶核静止时，波尔兹曼方程才可以直接使用。实际情况是，在实验室参考坐标系下，靶核是处于永不停止的无规则的热运动之中，靶核能量状态存在一个函数分布，对于单能的中子束，与靶核的相对能量就不再是一个单一的值，相对能量高于或低于共振能时，反应截面都比共振峰值小，最终结果是随展宽温度升高，共振截面曲线上共振峰的宽度更宽，同时峰值更低，如图1所示，240Pu核的(n,γ)共振截面的多普勒展宽，图中实线表示0K, 虚线表示30000K, 点划线表示300000K. 在共振核素的截面曲线上，随温度的增加共振峰的宽度增加，同时峰值截面也逐渐减小。实际反应堆物理计算都是在实验室参考坐标系下进行，所以把数据从相对参考坐标系转化为实验室参考坐标系的过程中，必须考虑截面的多普勒效应。'
                ],
                'pic': [
                  require('../assets/55ABC6E0545F97F2C02F9AAEA59_80525829_6850.png')
                ],
                'picTxt': '图1 240Pu核的(n,γ)共振截面的多普勒展宽'
              }
            ]
          },
          {
            'title': "热中子散射截面计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '根据热散射库（TSL）提供的数据，计算相干弹性散射、不相干弹性散射、不相干非弹性散射的微分、双微分散射截面，产生S(α,β,T)插值表，S(α,β,T)插值表的入射能量网格基于采用多点线性化产生，能与目标核素和温度相适应。',
                  '1) 相干弹性散射双微分截面',
                  '诸如石墨，金属Be，金属Fe等固体晶体是由规则的晶格构成，其晶格大小与热中子的波长相当。因此当热中子与这类材料碰撞后，中子散射具有衍射性质，即相干弹性散射反应。ENDF给出的相干弹性散射的双微分截面表达式为'
                ],
                'pic': [
                  require('../assets/8E8EDB121E6A5CCB10E1B62AE13_CA69860E_935.png'),
                  require('../assets/E87753F6CD808F103604120A395_22BA6C2E_2B7.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '式中：E­i为小于E的第i个Bragg边界/eV；'
                ],
                'pic': [
                  require('../assets/864CEEF0FCE6F3A86B3522E4B05_0EC68F9A_274.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '为结构因子相关量/eV∙b；'
                ],
                'pic': [
                  require('../assets/FBB1C50EBC00224CA14B8458471_E7C20DB3_16C.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '为每组晶格平面的特征散射角余弦。需要注意的是，'
                ],
                'pic': [
                  require('../assets/864CEEF0FCE6F3A86B3522E4B05_0EC68F9A_274.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '是关于E的1维插值表，而温度T不是插值变量，所以'
                ],
                'pic': [
                  require('../assets/864CEEF0FCE6F3A86B3522E4B05_0EC68F9A_274.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '仅用于温度T下的双微分截面计算。',
                  '2) 不相干弹性散射双微分截面',
                  '对于诸如氢化锆（ZrHx），聚乙烯（CH2）等含氢固体，材料中氢的自旋属性会破坏中子的衍射。热中子与这类材料碰撞后，会发生不相干弹性散射反应。ENDF给出的不相干弹性散射的双微分截面表达式为'
                ],
                'pic': [
                  require('../assets/D64EDAB96F5249763BC887EADD6_0CF3DB2C_8B0.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '式中：'
                ],
                'pic': [
                  require('../assets/8A704DF081D06C16E54FC5D07CE_B4D71BE1_16B.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '为特征束缚截面/b；W′为Debye-Waller因子/eV-1。',
                  ' 3) 不相干非弹性散射双微分截面',
                  '对于所有材料，中子与其碰撞后，都会发生不相干非弹性散射。ENDF给出的不相干非弹性散射的双微分截面表达式为'
                ],
                'pic': [
                  require('../assets/D9498B440C2EAFE1A76C3D56B43_63220532_C14.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '其中'
                ],
                'pic': [
                  require('../assets/78BC2F872DE6660B6893E10B34F_4BC94CCA_449.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '且'
                ],
                'pic': [
                  require('../assets/26F663315741B3FD6158F7026B7_EE74812D_335.png'),
                  require('../assets/0A3E69EFAC0A8BB67557C0006E6_E3195697_566.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '式中：NS为原子种类数; Mn为第n类原子的个数；β为无量纲能量转移；α为无量纲动量转移；Sn(α ,β,T)为ENDF提供的第n类原子的热中子散射律数据；σfn为第n类原子的自由原子散射截面/b；An为第n类原子与中子的质量比；A0为主要散射原子与中子的质量比。',
                  'ENDF中，Sn(α ,β,T)以插值表或解析表达式的形式给出。需要注意的是，插值表是关于α和β的2维插值表，而温度T不是插值变量，所以Sn(α ,β,T)仅用于温度T下的双微分截面计算。解析表达式采用短碰撞时间（SCT, Short-Collison-Time）近似，其表达式为'
                ],
                'pic': [
                  require('../assets/5E91E0D7D0D43CD740CBEEECEFB_85EEDD7F_BD9.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '式中：Teff(T)为ENDF提供的有效温度/K。',
                  '当使用Sn(α ,β,T)插值表时，较高入射能量以及较大散射长度（E-E′的值较大）所对应的α或β值可能超出了Sn(α ,β,T)插值表的网格范围。对于这种情况，也采用短碰撞时间近似来计算Sn(α ,β,T)。',
                  '4) 自由气体散射双微分截面',
                  '自由气体散射的双微分截面计算公式的形式与不相干非弹性散射一致（公式），区别在于Sn(α ,β,T)的表达式，自由气体散射的Sn(α ,β,T)表达式为'
                ],
                'pic': [
                  require('../assets/667A220715AACDCBB4B421F1762_5070CF7F_811.png')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '需要注意的是，该公式的理论基础认为中子的弹性散射截面为常数，对于弹性截面具有共振现象的核素，基于该表达式计算的双微分截面精度会大幅降低。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }, {
            'title': "不可辨共振区有效自屏截面计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '采用积分统计原理，基于有理变换-数值求积、傅里叶变换和共轭梯度法等方法，精确计算波动积分中的孤立项、序列内/间干涉项。在不可辨共振区，对于某一能量点E*，该点的有效自屏截面的求解可以等效为：求解在一个足够宽的能量段的平均截面，该能量段内包含足够多个共振峰，且所有共振峰的共振参数服从同一个概率分布，所服从的概率分布为ENDF给出的能量点E*处共振参数所满足的概率分布。x反应道（包括总反应道，弹性散射反应道，裂变反应道，辐射俘获反应道和竞争反应道）的0阶有效自屏截面可表示为'
                ],
                'pic': [
                  require('../assets/D1C82B08562A07FCDFC6485BB40_B0F2EA61_11B3D.jpg')
                ],
                'picTxt': ''
              },
              { 
                'subTitle': '',
                'txt': [
                  '式中：ΔE为能量段宽度/eV；σx(E)为x反应道截面/b；为0阶权重能谱。'
                ],
                'pic': [ ],
                'picTxt': ''
              }
            ]
          }, {
            'title': "热散射律数据产生模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '基于慢化材料的声子态密度、色散关系和极化坐标，生成包括相干/非相干弹性散射、相干/非相干非弹性散射的热中子散射律数据，用于制作反应堆中子学计算所需热中子散射数据库。热散射律数据引入非相干近似和声子展开方法进行计算。',
                  '热散射律数据通常分为三个不同的部分：',
                  '•相干弹性散射。对于诸如石墨或铍的晶体固体很重要。基于各向异性位移参数（ADPs）方法的技术，可以考虑原子间力的各向异性以及不同方向力的相关性，从而消除了传统方法中使用的立方近似和原子位置近似。',
                  '•非相干弹性散射。对于含氢固体（如甲烷，聚乙烯和氢化锆）非常重要。根据频谱计算Debye-Waller积分和有效温度。',
                  '•非弹性散射，包括相干部分和非相干部分。对所有材料都很重要。采用声子展开方法处理频谱的连续分量，采用自由气体模型或有效宽度模型描述液体材料中的扩散分量，离散谐振子模型用于描述多原子分子的振动/旋转运动。最后，通过Sköld近似考虑液体分子间的干涉效应。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }, {
            'title': "不可辨共振区概率表产生模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '基于Ladder Sampling方法抽样并统计截面得到不可辨共振区概率表，采用快速排序法提升概率表的产生速度。Ladder Sampling方法在不可辨共振能区内采用了“窄共振”假设，即在系统中中子由于散射造成的能量损失相对任意共振峰的宽度都是很大的，因此散射后中子的能量与共振结构不相关，中子会随机进入到任意共振峰；此外，共振峰影响的能量范围是很窄的，也就是不可辨共振能区内各能点处的截面主要来自其周围的共振峰的贡献。这个假设在不可辨共振能区内是可以达到的。',
                  '在这个假设下，不可辨共振能区中某个能量点下的实际共振结构就可以用该能量点附近的平均共振参数构造的随机共振序列来表示。',
                  'Ladder Sampling方法主要分为以下几个步骤：',
                  '1）构造共振序列：根据平均共振参数构造随机共振序列。',
                  '2）点截面计算：使用共振公式计算随机共振序列上各随机抽样能量点在不同温度下的点截面。',
                  '3）统计概率表：对计算得到的点截面进行统计得到概率表。',
                  '由于统计误差的存在，需要抽样多个共振序列来减少统计偏差，最后根据得到的概率表可以计算得到不同温度下的各背景截面下的自屏截面'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }, {
            'title': "释热及损伤截面计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '基于能量平衡法，直接法和运动学方法计算中子释放产生截面（KERMA因子）；基于NRT-DPA模型或ARC-DPA模型计算辐照损伤能产生截面，ARC-DPA模型的材料常数可以通过分子动力学数据或实验数据拟合得到。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }, {
            'title': "多群数据计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '根据反应率守恒原理计算多群截面、多群转移矩阵和多群裂变数据等参数；基于超细群方法精确计算共振区权重谱，基于考虑上散射效应的慢化方程求解器求解超热能区权重谱。',
                  '根据等价理论，对于某一个核素，当其处于无限均匀介质时的稀释截面与其处于非均匀介质时的等效稀释截面相同时，该核素在无限均匀介质中的共振能区有效自屏截面与非均匀介质中的共振能区有效自屏截面相等。在非共振能区，由于截面随入射能量缓慢变化，所以多群数据与实际问题能谱的相关性较低，因此可以求解核素在无限均匀介质中的非共振能区多群数据来代替非均匀介质中的非共振能区多群数据。因此，若要产生某一个核素的多群数据，仅需要建立包含该核素的多个无限均匀介质问题，然后计算出中子能谱作为权重能谱，最后根据多群数据计算理论计算多群数据，因此权重能谱的求解是获得精确多群数据的重要因素。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }, {
            'title': "中间共振因子计算模块",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '中间共振近似因子（或称Goldstein-Cohen因子、Lambda因子）是用来衡量一个靶核与中子发生弹性碰撞的能量损失相对于共振峰的宽度。基于NJOY2016产生WIMS格式多群数据库时，所有共振能群采用相同的平均Goldstein-Cohen因子。WIMS格式多群数据库不同核素的平均Goldstein-Cohen因子如图1所示。',
                  '因为压水堆中最重要的共振核素为U-238，因此，Goldstein-Cohen因子是可以看作衡量一个靶核与中子发生弹性碰撞的能量损失相对于U-238共振峰的宽度。当中子与靶核发生弹性碰撞的平均能量损失远大于共振峰的实际宽度时，对于靶核来说可以认为共振峰足够狭窄，即满足窄共振近似，Goldstein-Cohen因子为1。当中子与靶核发生弹性碰撞的能量损失足够小时，相当于假定靶核的质量无限大，则满足无限质量近似，Goldstein-Cohen因子为0。因此，靶核质量越小，其Goldstein-Cohen因子越接近于1，靶核质量越大，其Goldstein-Cohen因子越接近于0。'
                ],
                'pic': [
                  require('../assets/62B3DC3EE0B25BCEA6D9032A4EA_C023A80A_3072.png')
                ],
                'picTxt': 'Goldstein-Cohen因子随核素原子序数变化图'
              },
              { 
                'subTitle': '',
                'txt': [
                  '由于U-238在不同的能群具有不同的共振峰宽度，在较高能量段时，U-238的共振峰比较密集且狭窄，在较低能量段时U-238的共振峰比较稀疏宽松。所以，在能量段越高时，Goldstein-Cohen因子越接近于1，在能量段越低时，Goldstein-Cohen因子越接近于0'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          },
        ]
      },
      {
        'treeName': "软件确认",
        'treeCont': [
          {
            'title': "WLUP基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  '选取轻水慢化金属铀燃料(UME-LW)临界基准题、轻水慢化氧化铀(UO2-LW)临界基准题以及轻水慢化混合氧化物(MOX-LW)临界基准题对加工得到的MATXS格式多群截面数据库进行验证。以上问题的几何结构均为燃料棒栅元，采用DRANGON4程序，利用ENDF/B VII.1评价核数据库，基于自主开发的Atlas-C制作的361群中子MATXS多群截面数据库对以上问题的Keff进行了计算，并与利用NJOY2016制作得到的MATXS格式数据库的计算结果进行对比。WLUP单栅元基准题模型如图1所示。'
                ],
                'pic': [
                  require('../assets/34CB34D713BD41F4A2AAEAA25E5_4C8E52FB_5CA9.png')
                ],
                'picTxt': '图1 单栅元基准题模型'
              },
              { 
                'subTitle': '',
                'txt': [
                  '1) UME-LW临界基准题',
                  '共计算61个UME-LW问题，问题名称如表1所示'
                ],
                'pic': [
                  require('../assets/F67AAB22BB410946579733E8302_E45057BE_2D8A.png')
                ],
                'picTxt': '表1 测试中使用的UME-LW基准题'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/94DA3C1CA58AADA2776163C0481_4D2BACB3_12D1D.jpg')
                ],
                'picTxt': '图2 UME-LW基准题计算结果'
              },
              { 
                'subTitle': '',
                'txt': [
                  '图2分别给出了利用Atlas-C处理得到的MATXS格式截面数据库与NJOY2016处理得到的MATXS格式截面数据库的计算结果，由图可以看出，利用自主开发的Atlas-C制作的MATXS格式数据库与NJOY2016制作的核截面数据库在计算金属铀单栅元问题时的偏差均在20pcm以内，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。',
                  '1) UO2-LW临界基准题',
                  '选取53道UO2-LW基准题进行计算，计算的问题名称见表2,235U富集度范围为1.31%~5.74%。'
                ],
                'pic': [
                  require('../assets/A9710E476E69B282AE8A7C7C160_201C2790_46B5.png')
                ],
                'picTxt': '表2 测试中使用的UO2-LW基准题'
              },
              { 
                'subTitle': '',
                'txt': [
                  '图3给出了利用Atlas-C处理得到的MATXS格式截面数据库与NJOY2016处理得到的MATXS格式截面数据库的计算结果及其结果误差。由图3可知，自主开发的MATXS格式核截面数据加工平台制作得到的核截面数据库与NJOY2016制作得到的核截面数据库在计算UO2-LW基准题时偏差均在20pcm以内，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/403B216F19A326C956C0801D8C0_CE01639D_154C8.jpg')
                ],
                'picTxt': '图3UO2-LW基准题计算结果'
              },
              { 
                'subTitle': '',
                'txt': [
                  '1) MOX-LW临界基准题',
                  '选取30道MOX-LW基准题进行验证，计算的问题名称如表3所示。图4给出了利用Atlas-C处理得到的MATXS格式截面数据库与NJOY2016处理得到的MATXS格式截面数据库的计算结果，由图4可知，自主开发的MATXS格式核截面数据加工平台制作得到的核截面数据库与NJOY2016制作得到的核截面数据库在计算MOX-LW基准题时计算偏差均在20pcm以内，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/83792C4B31B812A5705F4FB1A62_A6BB19F4_20AD.png')
                ],
                'picTxt': '表3 测试中使用的MOX-LW基准题'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/DC01FC54169A5BB03CBDBB749BA_94C09848_12FAB.jpg')
                ],
                'picTxt': '图4 MOX-LW基准题计算结果'
              },
              { 
                'subTitle': '',
                'txt': [
                  '同时，由上述WLUP临界基准题中选取8道单栅元问题，对其燃料区以及慢化剂区的中子光子通量进行验证。验证结果如图5-1至图5-15所示，由图可以看出利用自主开发的Atlas-C与NJOY2016制作得到的MATXS格式数据库计算得到的单栅元问题的中子光子通量均吻合较好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/F5E8089E26AFD3824F839AF4F1E_52D17C1B_7892.jpg'),
                  require('../assets/7783AE8E6D27FA3BBBB203861BE_F675D5E7_7700.jpg')
                ],
                'picTxt': '图5-1aecl_um基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/F9996F25204451B047BBBEBA0DD_D46A9E40_64CD.jpg'),
                  require('../assets/08C5F589FE439E79AFF64AC7B13_7367036B_6653.jpg')
                ],
                'picTxt': '图5-2aecl_um基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/2E227AB8FFA86EF0CA4C70B02CD_6AE79B54_7C20.jpg'),
                  require('../assets/EEDE68441087785407B50A6FCDB_EB4876AF_7DDF.jpg')
                ],
                'picTxt': '图5-3 bnl_uma1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/1DB56FB7692D88B02FBD6E355C7_139C13B3_62AF.jpg'),
                  require('../assets/8CFEA2C6A60CB4EB4EDF36D4701_2EE2E04E_64CA.jpg')
                ],
                'picTxt': '图5-4 bnl_uma1基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/6A7FF32391B02C26602CBD08A35_BAA9B7B8_787F.jpg'),
                  require('../assets/783BB334B55E0AF60B6B419C46D_A7F6B3B5_62B9.jpg')
                ],
                'picTxt': '图5-4 bnl_umb1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/0D16F8CA312B6218773FAEACA00_46230AD3_78E1.jpg'),
                  require('../assets/00DA22EFA2FA66CF9E21F4ED581_3BD70F34_6325.jpg')
                ],
                'picTxt': '图5-5 bnl_umb1基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/383A918A765E005D5EE48E7B7D3_AD21C03E_78BF.jpg'),
                  require('../assets/B1FE174B3043A893F742ED4FAD5_E2A5946F_7A41.jpg')
                ],
                'picTxt': '图5-6 aeew_juno基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/1FC5B9504CB79C012A15D8CB5A6_6085CCBA_6661.jpg'),
                  require('../assets/2076E3B247A9C51A6789E881B03_82396D2D_665D.jpg')
                ],
                'picTxt': '图5-7 aeew_juno基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/6AD8AAF33F64C964889000ED5BD_6E7D8A5C_7BC9.jpg'),
                  require('../assets/DE162B17F6E828760F85AC9E079_9E70063B_7D65.jpg')
                ],
                'picTxt': '图5-8 bapl1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/9A6DA2743D04398223FDE929FCD_6A6F701C_62AE.jpg'),
                  require('../assets/2658E0A93E7590DB683CE1B8EA7_4F216F5D_6546.jpg')
                ],
                'picTxt': '图5-9 bapl1基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/89DBCF7CE083B32F69D578E7A25_AFA7BD09_7986.jpg'),
                  require('../assets/E5FC5F02848E47C98F57913170C_AEA61EBC_7BC7.jpg'),
                ],
                'picTxt': '图5-10 jaeri_tca1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/2A4C2339BED156E30A61C6D9BD4_BA247822_6331.jpg'),
                  require('../assets/021979EF9C19E568BA811EBE46B_73D7A8B1_6636.jpg')
                ],
                'picTxt': '图5-11 jaeri_tca1基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/1CBD9F777116C2297AB0089CE73_EDF661A6_7BE6.jpg'),
                  require('../assets/C7678FBB05AE73B711E80D33C40_8C8BE562_651E.jpg')
                ],
                'picTxt': '图5-12 bnw_pua1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/634E59EF4393733FBE878A51C48_F3A11FBE_7CFC.jpg'),
                  require('../assets/44656A62B410C1A7E9B7165E30E_B64E614A_66FA.jpg')
                ],
                'picTxt': '图5-13 bnw_pua1基准题燃料区、慢化剂区光子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/F40E81DFEF092699F94F8A6C802_43C0C92B_798E.jpg'),
                  require('../assets/B9566BF79799748FE3BF3DE9CFB_2646030E_7B1A.jpg')
                ],
                'picTxt': '图5-14 ge_pu1基准题燃料区、慢化剂区中子通量'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/F13FB14BE43EA5812D881766039_C2372697_5F99.jpg'),
                  require('../assets/0DD3896C0BE190555D4AD6070A0_F0CD9637_6118.jpg')
                ],
                'picTxt': '图5-15 ge_pu1基准题燃料区、慢化剂区光子通量'
              }
            ]
          },
          {
            'title': "RNFC基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'RFNC基准题为光子泄漏谱测量基准题，实验装置如下图5-21所示，其球壳中心为14MeV中子源，源分布为各向同性，计算的球壳内径为5厘米，外径为10厘米，测量球壳中分别填充不同材料时球壳外侧的光子泄漏谱。选取中子能群结构为174群，光子能群结构为121群，由于源项为14MeV单能中子源，因此在SN程序验证过程中采用在13.84MeV-14.191MeV内的均匀分布。共选取在屏蔽设计中常用的20种核素进行验证，验证材料的核素信息及质量密度如表5-4所示，选取ENDF/B VII.1评价核数据库，温度为300K。'
                ],
                'pic': [
                  require('../assets/09D7FEB7992906098E82DDE22E4_CB8E1E77_E1F5.jpg')
                ],
                'picTxt': '图1 RFNC基准实验装置'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/33B6CE36774A5E00A859B1B23AF_3AD6F452_3F4B.png')
                ],
                'picTxt': '表1 球壳验证材料及其质量密度'
              },
              { 
                'subTitle': '',
                'txt': [
                  '对计算模型采用基于MATXS格式多群数据库的多群SN程序建模计算，模型如图2所示。'
                ],
                'pic': [
                  require('../assets/7ED16562497380BED7A257272C1_2F2F6EC2_3BC3.png')
                ],
                'picTxt': '图2 RFNC基准题简化模型'
              },
              { 
                'subTitle': '',
                'txt': [
                  '图3至图22分别给出了以上述20种核素作为屏蔽材料的光子泄漏谱，每种材料分别给出了利用自主开发的Atlas-C制作得到的MATXS格式数据库与NJOY2016制作得到的数据库的SN程序计算结果。图中横坐标是光子能量(MeV)，纵坐标是给定源强下归一化光子通量。从图中可以看出，自主开发的Atlas-C制作得到的MATXS格式数据库与NJOY2016制作得到的数据库的SN程序计算结果吻合较好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。因此，利用自主开发的Atlas-C制作得到的MATXS多群数据库在整体上是可信的。'
                ],
                'pic': [
                  require('../assets/4ABC5330182EEE0784BCC0324AF_D027BE68_EB70.jpg')
                ],
                'picTxt': '图3 Al球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/120A812F9F01C46A689CEADF2DD_75596E5E_C93E.jpg')
                ],
                'picTxt': '图4 Cu球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/F2217BEAD0EF5AB98B937DC4A69_FF920E9A_C376.jpg')
                ],
                'picTxt': '图5 Fe球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/EB30C00B628B9B2C765699F10D2_B46CE7F7_BF29.jpg')
                ],
                'picTxt': '图6 H2O球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/1CC9C335E2A71407AEB26762427_1FA5E3CF_DAD7.jpg')
                ],
                'picTxt': '图7 NaCl球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/CBAAA239DB151E7FB3CD1C605D4_186DAB1E_AEF6.jpg')
                ],
                'picTxt': '图8 Ti球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/C8AB6F3854F785C47A4E4367DD0_69229B5B_AF5E.jpg')
                ],
                'picTxt': '图9 U238球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/676EA08AFB08CC28DEA8201A5E1_3A7F08F0_AFE2.jpg')
                ],
                'picTxt': '图10 Be9球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/E05991ADC342D2C944186A7213D_4BFA6C9D_ACF1.jpg')
                ],
                'picTxt': '图11 Cnat球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/52804F7784846BC6460DA77E301_41CBA3EF_CEE9.jpg')
                ],
                'picTxt': '图12 Cr52球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/43DECB09EDF978DB2869B7C3223_A4D4841F_A1E0.jpg')
                ],
                'picTxt': '图13 F19球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/85EA734A47032932CEFD4E589AE_4E0540AF_D856.jpg')
                ],
                'picTxt': '图14 Li6球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/AF533AEE90D1E7EF48609F7A2E6_2D1B7AAC_A336.jpg')
                ],
                'picTxt': '图15 Li7球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/045AC0DBB010F31AD3B058763F4_B9852D01_C990.jpg')
                ],
                'picTxt': '图16 Mn55球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/5304EB000B64A1C61B5A3EC0708_684F226C_BFD5.jpg')
                ],
                'picTxt': '图17 Ni球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/CAD5AD251F7AC10B58DF1264DFB_CCBEF756_BE08.jpg')
                ],
                'picTxt': '图18 P31球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/CCE19B5AF86D6B579E83DB0358A_6C5C37C3_B62E.jpg')
                ],
                'picTxt': '图19 Snat球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/D7232FBC7CD87121AA88CEAFE1C_225615CF_B96E.jpg')
                ],
                'picTxt': '图20 Vnat球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/8AC3EFE910A9D53644C7FBF1106_A72B3DA2_B886.jpg')
                ],
                'picTxt': '图21 Wnat球壳的光子归一化通量'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/FF672487D32CB299784C2942D7A_68FC4BC9_DF2B.jpg')
                ],
                'picTxt': '图22 SiO2球壳的光子归一化通量'
              }
            ]
          },
          {
            'title': "ASPIS基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'ASPIS基准实验是在NESTOR石墨慢化水冷堆中进行的热中子屏蔽实验，实验装置如图1所示，其屏蔽装置由不同厚度的屏蔽板组成，屏蔽板厚度及材料如表1所示。'
                ],
                'pic': [
                  require('../assets/90421F340EE5E9305321BAD8148_61D824F9_135D2.png')
                ],
                'picTxt': '图1 ASPIS实验装置说明'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/5A290261D6397B875323A0F5A20_1EFDBBE4_89BF.png')
                ],
                'picTxt': '表1 屏蔽层材料说明'
              },
              { 
                'subTitle': '',
                'txt': [
                  '根据基准题中给出的几何材料信息进行MATXS数据库制作以及建模，制作数据库过程中选取ENDF/B VII.1评价核数据库，选取能群结构为中子174群，光子38群，输运计算采用自主开发的SN程序，建立的模型如图2所示。统计不同屏蔽位置处中子光子通量，并计算得到不同反应道对应的微观反应率与实验结果进行对比，图5-45-图5-48给出了轴向不同位置处Mn55(n,g)、S32(n,p)以及Rh103(n,n’)反应道的反应率对比，计算值与实验结果的比值C/E基本在0.8-1.2之间，且利用自主开发的核截面数据库加工平台制作的MATXS格式数据库与NJOY2016制作的数据库计算结果吻合良好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/08D3E001B96678C8CFD96986F78_A0263B4B_6F9.png'),
                  require('../assets/FF8B1E82C16C73A8DE343F16702_354D27DB_A6BD.png')
                ],
                'picTxt': '图2  ASPIS几何模型建模及裂变板处几何模型'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/F72F320898AE81A0644A7129BDE_ADE1B005_6622.jpg')
                ],
                'picTxt': '图3 轴向不同位置处Mn55(n,g)反应率C/E值对比'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/A6EA39742230132E6221A322677_67D50B06_5FC8.jpg')
                ],
                'picTxt': '图4 F4截面处不同高度Mn55(n,g)反应率C/E值对比'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/76067EF1C067DF5D740CC36E2CB_C4E69C96_5411.jpg')
                ],
                'picTxt': '图5 轴向不同位置处S32(n,p)反应率C/E值对比'
              },
              { 
                'subTitle': '',
                'txt': [],
                'pic': [
                  require('../assets/CFC9F6C887D41A8CFBE670BC997_18CBD451_4D60.jpg')
                ],
                'picTxt': '图6 轴向不同位置处Rh103(n,n’)反应率C/E值对比'
              }
            ]
          },
          {
            'title': "NAIADE基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'NAIADE基准实验是在法国重水堆中进行的屏蔽实验，实验装置如图1所示，其屏蔽装置由不同厚度的屏蔽板组成，屏蔽板厚度及材料如表1所示。'
                ],
                'pic': [
                  require('../assets/1A9BCDC963362677902DAFD43C9_EA313B4A_20A82.png')
                ],
                'picTxt': '图1 NAIADE屏蔽实验装置说明'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/BD0FD5854909C1C67198AD03EB5_56D747AF_25DB1.jpg')
                ],
                'picTxt': '表1 屏蔽层材料说明'
              },
              { 
                'subTitle': '',
                'txt': [
                  '根据基准题中给出的几何材料信息进行MATXS数据库制作以及建模建立的模型如图2所示，制作数据库过程中选取ENDF/B VII.1评价核数据库，选取中子能群结构为174群，计算过程中不考虑光子，输运计算程序选择自主开发的SN程序，统计屏蔽材料不同位置处中子光子通量，并计算得到不同反应道对应的微观反应率与实验结果进行对比，图3和图4给出了轴向不同位置处Mn55(n,g)反应道的等效热中子通量密度对比，计算值与实验结果的比值C/E基本在0.8-1.2之间，且利用自主开发的核截面数据库加工平台制作的MATXS格式数据库与NJOY2016制作的数据库计算结果吻合良好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/83C4FB41288C158359683544743_1549CCCA_2D7D.jpg')
                ],
                'picTxt': '图2 NAIADE基准题模型建模'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/28A16BD23B0D865103271676BB7_3EA4D5AE_4DB8.jpg')
                ],
                'picTxt': '图3 水屏蔽材料不同厚度处Mn55(n,g)反应道等效热通量密度对比'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/4886BAF76089F56E564FC8E2693_F979BF7D_5148.jpg')
                ],
                'picTxt': '图4 铁屏蔽材料不同厚度处Rh103(n,n’)反应道等效裂变通量对比'
              }
            ]
          },
          {
            'title': "TUD_FNG基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'TUD_FNG是在聚变堆ITER进行的用于测量中子光子能谱的基准实验，实验装置如图1所示，屏蔽板厚度及材料如表1所示。'
                ],
                'pic': [
                  require('../assets/3AD535640543A3D2AB0FE58E74D_3046818A_8359.png')
                ],
                'picTxt': '图1 TUD_FNG基准实验装置'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/D73F5178733EC4F0786CA50C5D3_9AFEE473_23018.jpg')
                ],
                'picTxt': '表1 TUD_FNG基准实验材料层说明'
              },
              { 
                'subTitle': '',
                'txt': [
                  '基于基准实验中给出的几何结构以及材料信息，根据基准题库中TRANSX输入卡以及DORT输入卡，分别利用自主开发的Atlas-C以及NJOY2016制作MATXS格式数据库，其中评价库版本选择为ENDF/B VII.1，选取能群结构为中子175群，光子38群，然后利用基准题中给定的TRANSX卡片进行自屏计算，同时将自屏后的宏观截面转换为fort.8文件，制作得到可供DORT读取的截面库对上述基准题进行计算。具体截面处理流程如图2所示，需要注意的是，由于缺少首次碰撞源计算程序GRTUNCL，因此与基准实验中给出的结果相比未进行碰撞源修正，使用的TRANSX版本为2.15版本，DORT版本为3.2。'
                ],
                'pic': [
                  require('../assets/06EA79A06FFFE42C89720E2582D_BAB20A31_988.png')
                ],
                'picTxt': '图2 TUD_FNG基准题计算流程'
              },
              { 
                'subTitle': '',
                'txt': [
                  '基于图2所示计算流程，图3分别给出了位置A处利用自主开发的Atlas-C以及NJOY2016制作得到的MATXS格式数据库计算得到的中子光子通量。由图可以看出，利用自主开发的Atlas-C以及NJOY2016制作得到的MATXS格式数据库计算结果吻合良好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [
                  require('../assets/3DF49D7AAFA01CCD5A7ADE9FA1B_44B0DBA2_1D09.jpg'),
                  require('../assets/33B88A789BFDF8D31BE31EDE454_DF4C271B_198F.jpg')
                ],
                'picTxt': '图3 TUD_FNG基准题 A处中子、光子通量对比'
              }
            ]
          },
          {
            'title': "FNG基准题",
            'date': '2020-08-25',
            'content': [
              { 
                'subTitle': '',
                'txt': [
                  'FNG系列基准题均为聚变屏蔽基准题，其实验装置与TUD_FNG基准题一致，如图1所示。'
                ],
                'pic': [
                  require('../assets/3AD535640543A3D2AB0FE58E74D_3046818A_8359.png')
                ],
                'picTxt': '图1 TUD_FNG基准实验装置'
              },
              { 
                'subTitle': '',
                'txt': [
                  '基于基准实验中给出的几何结构以及材料信息，根据基准题库中TRANSX输入卡以及DORT输入卡，分别利用自主开发的Atlas-C以及NJOY2016制作MATXS格式数据库，其中评价库版本选择为ENDF/B VII.1，选取能群结构为中子175群，光子38群，然后利用基准题中给定的TRANSX卡片进行自屏计算，同时将自屏后的宏观截面转换为fort.8文件，制作得到可供DORT读取的截面库对上述基准题进行计算。其计算流程与TUD_FNG基准题相同，与实验结果相比也未进行碰撞源修正。在计算过程中使用的TRANSX版本为2.15版本，DORT版本为3.2。'
                ],
                'pic': [
                  require('../assets/A381E6AA179CD8B60EC7D086E4E_3727BF14_205E.jpg'),
                  require('../assets/EB0A82F9A0D8396F0F93D59EA29_9217AA2B_1BFD.jpg')
                ],
                'picTxt': '图2 FNG_SIC基准题中子、光子通量对比'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/8E8C4B17A810A0FDB69E3120FC3_EB57B1DC_1CA0.jpg'),
                  require('../assets/22054A31CCD07461D55FE3D7BB6_4E70163D_1A96.jpg')
                ],
                'picTxt': '图3  FNG_SS基准题中子、光子通量对比'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/CE3C672DE8F606D5E46E796D0BA_EDA0158A_1FD5.jpg'),
                  require('../assets/0C1337E271A1D13DD066BA359EB_3E9097CE_19DF.jpg')
                ],
                'picTxt': '图4 FNG_STR基准题中子、光子通量对比'
              },
              { 
                'subTitle': '',
                'txt': [

                ],
                'pic': [
                  require('../assets/6EDF33DDB7303E4BD70B088754A_4ECC08CD_1A7A.jpg'),
                  require('../assets/B86934B6A187210AF36717D57E8_C136ED1E_1B7D.jpg')
                ],
                'picTxt': '图5 FNG_W基准题中子、光子通量对比'
              },
              { 
                'subTitle': '',
                'txt': [
                  '图2至图5为FNG系列基准题屏蔽材料分别为碳化硅、不锈钢、结构材料以及钨，利用自主开发的Atlas-C和NJOY2016制作数据库计算得到的中子光子通量对比。由上图结果可知，利用自主开发的Atlas-C和NJOY2016制作的到的数据库计算得到的中子光子通量计算结果吻合良好，引起偏差的原因主要为两个程序在进行多群计算是选取的能量网格以及积分方式不同。'
                ],
                'pic': [],
                'picTxt': ''
              }
            ]
          }
        ]
      },
      
    ],
    hotNewsList: [  // 热点新闻
      { 
        "title": '西安交大NECP团队代表参加RPHA19会议',
        "date": '2019-12-08' ,
        "content": [
          {
            "subTitle": '',
            "txt": [
              '2019年12月2日至12月3日，RPHA19 (Reactor Physics Asia 2019)会议在日本大阪召开。西安交通大学核工程计算物理实验室（NECP）包括吴宏春教授在内的14名代表参加了本次会议。此外，中国核动力研究设计院的柴晓明、韩国科学技术院的Yonghee Kim和日本名古屋大学的Akio Yamamoto均出席了此次会议，其中Akio Yamamoto担任大会主席。',
              'RPHA19旨在针对反应堆物理计算中的核心关键技术和学术问题进行充分交流。会议上三个国家的专家、学生就堆芯分析方法、蒙特卡洛计算方法、敏感性和不确定度分析方法等话题进行了全面、系统的交流探讨。会议最后，NECP团队的1名硕士研究生荣获“Best Presentation”的荣誉，为本次NECP大阪之行画上了圆满的句号。'
            ],
            "pic": [
              require('../assets/1413B9847C90E071061F7FD7AAC_04342C22_20C73.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              ''
            ],
            "pic": [
              require('../assets/BF25F320DC51B6CF393C85EF5CE_DBF776E0_12A6B.jpg')
            ],
            "picTxt": ''
          },
        ]
      },
      { 
        "title": '第二届西安交大NECP团队&韩国蔚山科技大学CORE团队交流研讨会顺利举行',
        "date": '2019-08-12' ,
        "content": [
          {
            "subTitle": '',
            "txt": [
              '2019年7月29日至8月1日，由西安交通大学核工程计算物理实验室（NECP）举办的第二届西安交大NECP团队&韩国蔚山科技大学CORE团队学术交流研讨会在西安交大顺利举行。韩国蔚山科技大学（UNIST）的CORE团队是韩国高校中最大的反应堆物理研究团队，代表了韩国高校反应堆物理研究的最高水平，也是国际知名的一流团队。本次会议旨在针对反应堆物理计算中的核心关键技术和学术问题进行充分交流，从而促进NECP打造世界一流科研团队，加强创新港内涵建设，助力交大构建世界一流大学。'
            ],
            "pic": [
              require('../assets/B407EB3774FE122AA7624C6C6FF_2662DB92_38202.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '参加此次研讨会的学者有：来自参加此次研讨会的学者有：来自UNIST的Deokjung Lee 教授及其它4名实验室成员；来自韩国庆熙大学的Ser Gi Hong教授；以及西安交大NECP实验室的所有成员，包括吴宏春教授、曹良志教授、郑友琦教授及其它7名教工和60余名在读研究生。'
            ],
            "pic": [
              require('../assets/48456930811DA38958A09C2701A_B84C2A50_44B9D.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '本次会议进行了为期两天的学术交流，本次会议进行了为期两天的学术交流，NECP团队与CORE团队从反应堆核数据处理方法、组件计算方法、堆芯分析方法、蒙特卡洛计算方法与敏感性和不确定度分析方法等方面，对核反应堆物理分析技术进行了全面、系统的交流探讨。双方均对对方团队的研究工作进行了高度评价，并对反应堆物理计算中的关键技术达成了共识。此外，双方表示今后要加强合作，并将XJTU-NECP & UNIST-CORE系列研讨会在未来持续进行下去，以发挥其积极作用。'
            ],
            "pic": [
              require('../assets/301ECCDE9B981DA2A2BC97B339D_AA79DA31_58F19.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '不忘初心，牢记使命。目前西安交大正处于二次西迁的前夕，其发展已经到了咬紧牙关、屏息发力的时候，NECP作为西安交大的一份子，必然也需全力累积发展势能，为在创新港打造世界一流学术平台，在新一代高保真反应堆物理分析领域跨入世界一流领跑行列奋力前行。“交大人，干就干出个好样子！”'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      },
      { 
        "title": 'NECP团队参加第14届国际核数据大会（ND2019)',
        "date": '2019-05-26' ,
        "content": [
          {
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/7471557ED894852E64549D8F149_7C45FA32_20B70.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '2019年5月19日至24日，由经济合作与发展组织核能署（OECD/NEA）和国际原子能机构（IAEA）主办，中国核工业集团有限公司、中国原子能科学研究院、中国核数据中心承办，中国核学会协办的“第14届国际核数据大会”（英文名：2019 International Conference on Nuclear Data for Science and Technology）(简称ND2019)在北京国家会议中心召开。本次大会主题为“核数据——人类的共同资产”，来自45个国家、地区以及相关国际组织、机构的核数据、核科学与核工程领域学者专家以及国内各科研院所、高校代表及关注核数据领域的各界人士等共计约500名代表参会。',
              '国际核数据大会是国际核数据界规模最大、水平最高的学术交流会议。这是大会首次在中国召开，也是首次在OECD成员国之外的国家召开。',
              '西安交通大学核反应堆计算物理实验室（NECP）在祖铁军副教授、万承辉助理研究员的带领下共计9人参加了本次会议。'
            ],
            "pic": [
              require('../assets/F3FD3391D6354A37C687865A391_7A749E4D_191BF.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '祖铁军副教授作《Progress of the Development of the Nuclear Data Processing Code NECPAtlas》邀请报告，万承辉助理研究员作《Researches on Nuclear-data Adjustment for the Sodium-cooled Fast Reactor》和《Researches on Uncertainty Quantification Due to Nuclear-data Covariance for PWR and SFR》报告，博士生徐嘉隆作《Development and Verification of Resonance Elastic Scattering Kernel Processing Module in Nuclear Data Processing Code NECP-Atlas》报告，博士生尹文作《Application of hyperfine group self-shielding calculation method to lattice and whole-core physics calculation》报告，博士生汤勇强作《Development and verification of the thermal scattering law processing module in nuclear data processing code NECP-Atlas》报告。',
              '通过本次大会，NECP团队向同行展示了近期的研究状况，同时也深入了解同行最新的研究进展。'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      },
      { 
        "title": ' NECP团队参加第九届反应堆物理与核材料学术研讨会',
        "date": '2019-03-30' ,
        "content": [
          {
            "subTitle": '',
            "txt": [
              '第九届反应堆物理与核材料学术研讨会于2019年3月25日至29日在湖北宜昌举行。本届研讨会由三峡大学承办，注册人数达到160多人，为历届最多，其中包括来自西安交通大学、上海交通大学、清华大学、哈尔滨工程大学、中科院核能安全技术研究所、中科院上海应用物理研究所、北京应用物理与计算数学研究所、华龙国际核电技术有限公司等近20家高校和研究单位的领导与代表。西安交通大学核反应堆计算物理实验室（NECP）在吴宏春教授、曹良志教授的带领下共计18人参加了本次会议。'
            ],
            "pic": [
              require('../assets/2B0CE3531FEFA89EC167290CB72_19EFC8D9_1E8E9.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '大会第二天，吴宏春教授发表大会报告《基于栅元尺度中子学计算的挑战与实现》，报告主要介绍了在栅元尺度的中子学计算中存在的挑战，NECP实验室为了解决这些问题自主研发了压水堆三维堆芯Pin-by-Pin燃料管理程序NECP-Bamboo2.0，并在报告中给出了NECP-Bamboo2.0与常见的两步法组件均匀化中子学计算程序的对比结果。',
              '在大会期间NECP团队完成共计12人次的报告，报告内容包含了快堆计算程序SARAX2.1近期的改进与验证、三维六角形SN节块法的加速和并行、适用于小型压水堆堆芯的改进两步法研究、NECP-Bamboo程序板元件堆芯物理计算、超细群方法的应用的研究、NECP-X程序复杂几何的多物理耦合计算、NECP-X源项计算功能的开发与验证、NECP-X/CTF验证、三维MOC、钠冷快堆不确定度量化、自给能探测器模拟等最新研究成果，吸引了国内同行极大的兴趣。其中徐志涛的《SARAX中三维六边形SN节块法的数值加速和空间并行》和温兴坚的《NECP-X源项计算功能的开发与验证》，荣获大会优秀报告。',
              '通过本次大会，NECP团队向国内同行展示了近期的研究状况，同时也深入了解了国内同行最新的研究进展。'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      },
      { 
        "title": '国家重点研发计划磁约束聚变专项青年项目启动暨聚变包层设计技术学术研讨会在我校举行',
        "date": '2018-10-19' ,
        "content": [
          {
            "subTitle": '',
            "txt": [
              '10月19日，由西安交通大学承担的国家重点研发计划磁约束聚变专项三个青年人才项目启动会暨聚变包层设计技术学术研讨会在西安召开。中国科学技术大学万元熙院士，西北核技术研究所欧阳晓平院士，西安交通大学王铁军副校长，西安交通大学科研院黄忠德常务副院长，综合处王庆琳副处长，以及来自中国工程物理研究院、清华大学、北京大学、中国科学技术大学、哈尔滨工业大学、中科院等离子体物理研究所、国家电投中央研究院、核工业西南物理研究院等单位的专家出席了会议。会议由科研院常务副院长黄忠德主持。'
            ],
            "pic": [
              require('../assets/4D980617F251027CBE0E1658A7B_E33607F3_44E70.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '西安交通大学副校长王铁军代表学校致辞，向各位专家多年来对我校核学科的关心和支持表示感谢，并介绍了西部科技创新港的建设情况，重点介绍了西安交大核科学与技术学科的规划与建设，他强调，核科学与技术事关国家安全，学校非常重视该学科的发展。科研院综合处王庆琳副处长介绍了国家重点研发计划经费执行的相关规定。',
              '西安交通大学电气学院李兴文教授，核科学与技术学院田文喜教授、曹良志教授，分别针对各自主持的项目做了项目启动报告，从立项背景与国内外现状、技术路线与实施方案以及目前的进展等方面进行了详细介绍。三个项目分别针对毛细管等离子体模拟ELM高热负荷对PFMs侵蚀、CFETR包层多物理耦合分析、高保真的核热耦合数值模拟方法开展基础理论研究。与会专家进行了详细的讨论，对项目的实施提出了许多建设性的意见。',
              '受会议邀请，磁约束核聚变专家、中国工程院院士万元熙研究员做了“聚变堆的TBR问题与挑战—关于聚变能源堆的氚自持”的报告，介绍了我国磁约束聚变能发展技术路线，并对影响聚变能源堆氚自持的关键问题进行了详细分析。西安交通大学吴宏春教授做了“聚变堆包层设计软件自主化与创新设计关键问题研究”的报告，建议积极开展包层设计软件自主化以及包层的创新设计研究，提高我国聚变包层的设计能力。与会专家经过充分讨论，形成了广泛共识，建议国内相关优势单位强强联合，紧扣需求，针对聚变堆包层设计自主化软件开发、包层创新设计等开展系统深入的研究。'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      },
    ],
    newsList: [ // 新闻中心
      { 
        "title": '西安交大NECP团队成功高精度预测“华龙一号”全球首堆的临界硼浓度',
        "date": '2021-02-23' ,
        "content": [
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/news_item01.jpg')
            ],
            'picTxt': ''
          },
          {
            "subTitle": '',
            "txt": [
              '2020年10月21日15时09分，我国自主研发的三代核电“华龙一号”全球首堆福清核电厂5号机组首次达到临界，向建成投产迈出了重要一步。',
              '科技创新服务国家需求，扎根西北践行“西迁精神”，西安交通大学NECP团队经过十余年的理论研究，依托中国西部科技创新港的科研平台，研发了最新的、具有完全自主知识产权的先进压水堆燃料管理软件Bamboo-C（两步法）和数值反应堆确定论高保真计算软件NECP-X（一步法）。福清核电厂业主公司和NECP团队分别采用Bamboo-C和NECP-X软件，背靠背地建立了“华龙一号”精细的堆芯计算模型，提前预测了“华龙一号”首次临界的堆芯关键安全物理量——临界硼浓度。经与“华龙一号”首次临界状态的实测值对比，Bamboo-C和NECP-X软件对临界硼浓度的计算值与实测值之间的误差仅为1ppm和9ppm，预测精度高于国际同类计算软件。值得注意的是，这也是国际上首次应用一步法高保真确定论计算软件高精度预测商用核电站首次临界的临界硼浓度，充分证明了NECP-X软件在商用核电厂中实际应用的效果。'
            ],
            "pic": [
              require('../assets/7F6622003031F6F829BCA7C1298_BECBB03C_1A0DA.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '“华龙一号”是中国完全自主知识产权的第三代核电技术，也是中国核电走出去的旗舰，作为中国制造高技术、高标准、高经济带动性的典型代表，已成为国家的一张新名片。据了解，2015年5月5日国家核安全局颁发福清核电厂5号机组建造许可证，2015年5月7日福清核电厂5号机组核岛基础浇灌第一罐混凝土（FCD），“华龙一号”全球首堆终于生根落地。'
            ],
            "pic": [
              require('../assets/34CEC52EB1B8A1A6E00F7FED403_E1EEF6B7_1E3CF.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '在“华龙一号”福清核电厂5号机组首次临界后，NECP团队将继续开展其零功率物理试验、反应堆瞬态试验等关键节点的模拟计算，为该机组顺利满功率运行保驾护航，以科技成果服务国家重大需求，助力我国核能事业发展。'
            ],
            "pic": [],
            "picTxt": ''
          },
        ]
      },
      {
        'title': "核创公司顺利通过方圆标志认证集团“三体系”监督审核",
        'date': '2021-02-23',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2020年12月10日～11日，西安核创能源科技有限公司接受了方圆标志认证集团陕西有限公司（以下简称方圆）的“质量管理体系认证（QMS）”、“环境管理体系认证（EMS）”和“职业健康安全管理体系认证（OHSMS）”的监督审核。今年恰逢职业健康安全管理体系新版国家标准（GB/T 45001-2020）发布（2020年3月6日），公司予以高度重视，按公司惯例于体系外审前安排进行当年度公司内审。为提高工作绩效、更好地将贯标与日常工作结合，公司内审组将体系内审与自查工作结合起来，对公司各职能部门开展详尽有效的自查自检。',
              '本次监督审核评委组由一位标准化体系高级外审专家和一名审核员组成。首次会议上，评委组组长对本次审核的目的、内容、范围、依据等进行了详细说明。总经理徐嘉隆兼管理者代表，介绍公司各体系运行状况，要求各部门认真配合，并希望借助此次监督审核，使公司各项管理工作更加完善。',
              '在两天的监督审查过程中，公司管理层、研发部、服务部和综合部的主要负责人与评委积极沟通，两位评委通过材料审验、现场勘察等方式对公司各职能部门的贯标情况进行了调查，给出了积极肯定的评价，同时也针对公司在贯标中存在的漏洞，提出了改进建议。',
              '12月11日的末次会议上，评委组组长宣布：公司顺利通过2020年监督审核，同时建议换发新标准的OHSMS认证证书。评委组组长在末次会议上代表评委组对公司顺利通过监督审核表示热烈祝贺，他评价：公司虽成立时间不长，但能做到贯标工作与日常工作相结合，值得鼓励；公司管理体系日渐成熟，每年复审均有进步，导入卓越绩效管理体系后公司的质量管理水平已达到GB/T 19001-2016质量管理体系要求，软件质量有十足保障，企业经营业绩稳步提升，自我改进与完善机制越来越好。同时，针对GB/T 54001-2020标准换版提供了专业建议，要求公司各部门在2021年积极参与换版体系文件修订工作，加深对换版标准的了解，做好内部培训与宣贯工作。',
              '总经理徐嘉隆对于评委组的辛勤工作表示感谢，要求各部门再接再厉做好体系换版工作，不断提升公司贯标工作水平。并且要求各部门通过审核找到不足，对开具的不符合项，认真整改，举一反三，在以后的工作中查缺补漏。这次监督审核是为了使我公司的管理工作更上一个台阶，为实现公司“标准化、精细化、制度化”目标迈向新的征程。'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/news_item02.jpg')
            ],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "西安核创能源科技有限公司成功举办核电厂教育与信息化技术培训班",
        'date': '2021-02-23',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '为进一步提高核电厂培训管理人员及培训教员的技能，巩固核电厂信息化管理知识体系， 西安核创能源有限公司于2020年12月初，举办了核电厂教育与信息化技术培训班，此次培训班参训人员来自多家核电业主公司。在授课培训过程中，学员们主动参与课堂互动，积极与授课老师进行研讨交流，课堂气氛活跃，学习氛围浓郁。'
            ],
            'pic': [
              require('../assets/7E03B5A79F883A2CC66113A7E1A_C0AE13AE_14D74.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/5959D8471C55925AF58649A3224_D725CAA3_1147A.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/92A23E90E70E673AD277D1FBA7D_F674CA1F_FE44.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '本次培训涉及到了教学理论、教学方法、教师仪态、核电厂信息化建设、数据可视化基础等内容。课程涵盖了核电厂培训教员的授课方法、技巧、注意事项，培训课程与培训教材开发的系统化方法。同时，面向三、四代核电设计以及智慧核电厂建设，课程介绍了信息化管理在运行方面的管理优势，与技术方面的应用特点。',
              '通过此次培训，丰富了核电厂教员的理论知识和实操技巧，增加了面对各自学员时的自信心和应对突发状况的自我准备，在如今信息化普及度日趋增长的形势下学习了最新的科技成果，开阔了眼界，为今后的工作打下了基础，提供了方向，提升了干劲！同时，为使以后的培训班办的越来越好，学员们对培训过程中所存在的问题也充分表达了自己的意见和见解，我司都认真予以采纳。'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "第十八届反应堆数值计算与粒子输运学术会议 暨2020年反应堆物理会议顺利召开",
        'date': '2020-08-31',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2020年8月26日至29日，第十八届反应堆数值计算与粒子输运学术会议暨2020年反应堆物理会议（CORPHY2020）在西安交通大学南洋大酒店顺利举行，西安交通大学NECP实验室吴宏春教授担任大会主席。',
              '会议开幕式由吴宏春教授主持，中国工程院院士彭先觉、中国科学院院士吴宜灿、中国核学会计算物理学会反应堆数值计算与粒子输运专业委员会主任李庆、中国原子能院院长薛小刚、西安交大科研院院长黄忠德、西安交大核科学与技术学院院长田文喜等200余名科技工作者出席了开幕式。'
            ],
            'pic': [
              require('../assets/FC7090BADDEC15D11E01F160FCD_13A5806F_146A3.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '本次会议由西安交通大学和陕西省核学会共同承办，并由西安交通大学核工程计算物理实验室（NECP实验室）全体教师及20多名研究生志愿者负责具体组织。CORPHY2020共收到投稿170余篇，参会人员共计270余人，创历史新高；参会单位为包括中国原子能科学研究院、中国核动力研究设计院、上海核工程研究设计院、中广核研究院、北京应用物理与计算数学研究所、国家电投集团科学技术研究院、清华大学、西安交通大学、上海交通大学在内的20余家相关单位。会议共包含3个workshop、15个特邀报告以及170余个分会场报告，众多专家、学者与研究生就中子输运确定论方法/多物理耦合、堆芯分析方法与程序开发、蒙特卡罗方法/堆芯设计、电厂运行/燃料管理优化、核数据/VVUQ/物理实验等方面的研究进行了深入探讨，会场学术氛围浓厚、讨论积极热烈。会议期间还举办了“GSPn方法研讨会”，为堆芯输运计算提供了新思路。',
              '大会期间，还专门举办了“西安交通大学谢仲生教授从教62年座谈会”，来自全国各地的嘉宾和校友共同研讨谢仲生教授的教学科研思想。谢仲生教授1957年随交通大学西迁，1958年硕士研究生毕业后留校任教，是西安交通大学核反应堆工程（810）专业主要创始人之一。与会嘉宾表达了对谢老师60余年来为中国核工业所做重要贡献的崇高敬意，学生们对谢老师爱生如子、严慈相济的培养也表达了深深的感激之情。谢仲生教授、李佩芝教授夫妇出席了座谈会。会议还发行了《谢仲生教授论文集》。'
            ],
            'pic': [
              require('../assets/47EB8A86DFA86D5AC74F2847B64_4FDF9632_1B467.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '本次会议加强了全国各大高校、科研院所及工程单位的专家、学者和研究生在反应堆理论、数值计算、软件研发、燃料管理、反应堆核设计、工程应用等方面的交流，助力反应堆物理在我国未来核能发展中持续发挥重要作用。同时，此次会议充分展现了西安交通大学NECP实验室过去十余年间在反应堆物理领域取得的丰富的研究成果，也为实验室累积发展势能，于下个十年在新一代反应堆物理分析领域跨入世界一流领跑行列起到了积极促进作用。NECP实验室必将坚持自主创新初心，勇担核能强国使命，为我国建设核能强国奋力前行！'
            ],
            'pic': [
              require('../assets/A78131798019C2150FB26918803_71BF8E56_1965A.jpg')
            ],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "核创公司董事长和总经理等一行六人赴福建福清核电厂进行技术交流",
        'date': '2020-08-14',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '应福建福清核电厂技术支持处邀请，核创公司董事长和总经理等一行六人于2020年7月10日在福建福清核电厂技术支持处进行了友好深入的技术交流。',
              '会上，福建福清核电厂技术支持处对核创公司的来访表示热烈欢迎；我方首先汇报了我公司的商业压水堆设计计算程序Bamboo-C用于计算福清4个机组过去所有循环的计算结果。接着对于结果的比对和核电厂人员深入讨论，探寻出很多实际问题及需求，例如：甩负荷过程需要堆芯瞬态数值模拟，插棒瞬态过程；甩负荷过程会给定堆芯功率水平和控制棒棒位随时间的变化，需要关注∆I随时间的变化规律；启动物理试验的温度系数计算采用给定实测的临界硼的方式计算等。',
              '在解答了核电厂核物理范围的技术难题后，我方紧接着依次介绍了核数据处理软件Atlas-C、三维全堆芯pin-by-pin分析程序系统Bamboo2.0和高保真反应堆物理计算软件NECP-X。',
              'Atlas-C是由核创公司自主研发的核数据处理软件，基于先进的理论模型，对国际上主流评价核数据库进行处理产生堆用、屏蔽和燃耗（活化）等多用途的核数据库，打破了美国在核数据库程序上的垄断。'
            ],
            'pic': [
              require('../assets/4886499C42DC5C9AD967737879E_A5A8C798_1433E.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '三维全堆芯Pin-by-pin分析程序系统NECP-Bamboo2.0由核创公司在建立一整套全新的均匀化-参数化和多物理耦合等理论体系的基础上完全自主研发，可以实质性提高精细程度，显著降低分布量计算误差，已基本具备了商用堆分析计算的能力，还可以用于解决强泄漏、强非均匀的小型堆问题。该程序采用全组件非均匀直接计算、三维四群Pin-by-pin输运计算、三维全堆芯Pin-by-pin核-热-燃耗耦合，适用范围覆盖0%~20%和80%~100%的燃料富集度。',
              '目前核反应堆的数值模拟包括设计、校算、分析、评审等都逐渐向精细化发展，以期提前发现可能的安全隐患、释放过大的裕量以提高经济性。经过一整天的技术交流，我们从核电厂人员口中进一步了解到了很多核电厂的工程实际需求，对我们程序在使用中可提升的点进行了补足，也形成了一条未来改变人机交互模式的思路。交流双方都深感收获巨大，不忘初心继续坚持战略性合作，向保证核电厂安全和提高经济效益方向持续迈进。'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "核创公司董事长和总经理等一行六人赴海南昌江核电厂进行技术交流",
        'date': '2020-08-13',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '应海南昌江核电厂技术支持处邀请，核创公司董事长和总经理等一行六人于2020年8月11日在海南昌江核电厂技术支持处进行了友好深入的技术交流。',
              '会上，海南昌江核电厂技术支持处对核创公司的来访表示热烈欢迎；我方首先介绍了我公司的总体技术研究方向布局及其主要的阶段性进展和成果，然后依次针对高保真反应堆物理计算软件NECP-X和商业压水堆设计计算程序Bamboo-C进行了详细的介绍与技术讨论。',
              '高保真反应堆物理计算软件NECP-X，也称之为数值反应堆。该软件通过高精度模拟反应堆内各种核素各种粒子实际参与的物理过程，进行多物理耦合计算，可精准且直观体现反应堆内需要的各项数据为我们所用，是最新一代可适用所有堆型的先进计算软件。',
              '商业压水堆设计计算程序Bamboo-C，可适用目前国内所有压水堆，具有建模简易、使用方便、计算效率高，运算结果满足工业限值等优势，可解决电厂在实际生产中面临的诸多问题，提高换料大修效率，加快日常调峰速度等方面可直接显著增加电厂的经济效益，切实为我国核电事业增添活力。'
            ],
            'pic': [
              require('../assets/869C9877E8A6DFAAE717575F5F4_C505085F_27081.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '经过一整天的技术交流，让奋战在核工业一线的核电厂人员进一步了解了我公司的技术现状与规划，也让我们了解到了很多核电厂的工程实际需求，对双方的技术改进提供了思想碰撞的探索机会，也形成了一系列具体的思路和行动项，交流双方都深感收获巨大，并一致希望继续加强进一步的交流，为我国核工业的发展做出应有贡献。'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "加拿大沈炜教授访问NECP实验室并做学术报告",
        'date': '2019-12-09',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '应NECP实验室邀请，沈炜教授于2019年12月5日-11日来西安交通大学访问。2019年12月8日，沈炜教授在能动学院北二楼报告厅面向核能系做题为《Advances and Challenges on the Reactor-Physics Codes and Models Used in the Reactor Safety Analysis – A review of Canadian Experience》的学术报告。作为报告的主持，NECP实验室曹良志教授对沈炜教授的来访表示热烈欢迎。',
              '沈炜教授的报告以加拿大在CANDU堆芯物理建模模拟、安全分析和堆芯老化方面的工作，系统地介绍了核反应堆物理建模模拟方法以及安全分析方法。针对反应堆物理计算重点介绍了确定论方法和蒙特卡罗方法在核设计和安全分析中的应用以及存在的问题，并且详细地介绍了不确定性分析在安全分析中的关键作用。通过对加拿大已开展工作的回顾和介绍，引发听众对国内压水堆堆芯核设计、安全分析以及堆芯老化问题的思考。通过报告过程中与沈炜教授的深入交流，NECP实验室师生拓展了学术视野，受益匪浅。',
              '沈炜教授，现任加拿大重水堆业主集团有限公司（COG）研发部经理，加拿大核学会会士,西安交通大学以及加拿大皇家军事学院客座教授，加拿大核学会核科学与技术分会主席，美国核学会标准委员会ANS-19会员。曾任加拿大原子能有限公司反应堆物理首席专家。1993年在西安交通大学获得博士学位，1996年-1997年在韩国原子力研究院、1997年-1999年在加拿大蒙特利尔大学访问学者，1999年-2013年在加拿大原子能有限公司（现加拿大重水堆能源有限公司）任首席专家，2013年-2017年在加拿大核安全委员会任技术专家。'
            ],
            'pic': [
              require('../assets/2B71708121ED74C57C3655FB15B_F1B52F60_F74C6.png')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/2BE7F337607345746194D8C3BC0_7A02484F_13AD4.jpg')
            ],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "西安交大NECP团队代表参加RPHA19会议",
        'date': '2019-12-08',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2019年12月2日至12月3日，RPHA19 (Reactor Physics Asia 2019)会议在日本大阪召开。西安交通大学核工程计算物理实验室（NECP）包括吴宏春教授在内的14名代表参加了本次会议。此外，中国核动力研究设计院的柴晓明、韩国科学技术院的Yonghee Kim和日本名古屋大学的Akio Yamamoto均出席了此次会议，其中Akio Yamamoto担任大会主席。',
              'RPHA19旨在针对反应堆物理计算中的核心关键技术和学术问题进行充分交流。会议上三个国家的专家、学生就堆芯分析方法、蒙特卡洛计算方法、敏感性和不确定度分析方法等话题进行了全面、系统的交流探讨。会议最后，NECP团队的1名硕士研究生荣获“Best Presentation”的荣誉，为本次NECP大阪之行画上了圆满的句号。'
            ],
            'pic': [
              require('../assets/1413B9847C90E071061F7FD7AAC_04342C22_20C73.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/BF25F320DC51B6CF393C85EF5CE_DBF776E0_12A6B.jpg')
            ],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "NECP实验室参与中国实验快堆基准题例题联合研究项目第二次研究协调会",
        'date': '2019-11-04',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '西安交通大学NECP实验室快堆研究小组郑友琦教授、杜夏楠助理研究员和研究生李晓琪，于2019年10月28日至2019年11月1日在北京参加了由国际原子能机构（IAEA）牵头、中国原子能科学技术研究院主办的钠冷快堆基准例题联合研究项目第二次研究协调会。',
              '钠冷快堆基准例题联合研究项目是由IAEA在2018年6月发起，来自中国、法国、俄罗斯等16个国家28个研究机构共同承担的合作项目。旨在通过建立基于中国实验快堆首次物理启动测量数据的国际基准算例，对现有快堆程序精度和适用性进行全面的评估，进一步探讨数值模拟方法在先进堆芯计算中的经验与挑战。',
              '在会议中，杜夏楠助理研究员代表NECP实验室向各家单位展示了SARAX程序的数值模拟结果。该程序是由西安交通大学NECP团队经过多年研究，独立开发的具有完全自主知识产权的快堆核设计和分析程序，经过了国内外百余套数值算例和四系列二十余组堆物理实验数据的验证。此次用于中国实验快堆模拟，与实验值比较，SARAX程序展示了非常高的模拟精度。堆芯临界装载在背靠背的“盲算阶段（blind phase）”误差小于100pcm，是目前已提交的所有计算结果中精度最高的结果之一。'
            ],
            'pic': [
              require('../assets/0BF0E5ECABDB658EFECC480DD54_71A2CFBD_593EB.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/3E244ED9F885918D49C69D5B007_5612C892_28F78.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/42C4AC92AEB970B7622AD3B5135_25C93AA4_40A35.jpg')
            ],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "第二届西安交大NECP团队&韩国蔚山科技大学CORE团队交流研讨会顺利举行",
        'date': '2019-08-12',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2019年7月29日至8月1日，由西安交通大学核工程计算物理实验室（NECP）举办的第二届西安交大NECP团队&韩国蔚山科技大学CORE团队学术交流研讨会在西安交大顺利举行。韩国蔚山科技大学（UNIST）的CORE团队是韩国高校中最大的反应堆物理研究团队，代表了韩国高校反应堆物理研究的最高水平，也是国际知名的一流团队。本次会议旨在针对反应堆物理计算中的核心关键技术和学术问题进行充分交流，从而促进NECP打造世界一流科研团队，加强创新港内涵建设，助力交大构建世界一流大学。'
            ],
            'pic': [
              require('../assets/B407EB3774FE122AA7624C6C6FF_2662DB92_38202.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '参加此次研讨会的学者有：来自参加此次研讨会的学者有：来自UNIST的Deokjung Lee 教授及其它4名实验室成员；来自韩国庆熙大学的Ser Gi Hong教授；以及西安交大NECP实验室的所有成员，包括吴宏春教授、曹良志教授、郑友琦教授及其它7名教工和60余名在读研究生。'
            ],
            'pic': [
              require('../assets/48456930811DA38958A09C2701A_B84C2A50_44B9D.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '本次会议进行了为期两天的学术交流，本次会议进行了为期两天的学术交流，NECP团队与CORE团队从反应堆核数据处理方法、组件计算方法、堆芯分析方法、蒙特卡洛计算方法与敏感性和不确定度分析方法等方面，对核反应堆物理分析技术进行了全面、系统的交流探讨。双方均对对方团队的研究工作进行了高度评价，并对反应堆物理计算中的关键技术达成了共识。此外，双方表示今后要加强合作，并将XJTU-NECP & UNIST-CORE系列研讨会在未来持续进行下去，以发挥其积极作用。'
            ],
            'pic': [
              require('../assets/301ECCDE9B981DA2A2BC97B339D_AA79DA31_58F19.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '不忘初心，牢记使命。目前西安交大正处于二次西迁的前夕，其发展已经到了咬紧牙关、屏息发力的时候，NECP作为西安交大的一份子，必然也需全力累积发展势能，为在创新港打造世界一流学术平台，在新一代高保真反应堆物理分析领域跨入世界一流领跑行列奋力前行。“交大人，干就干出个好样子！”'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "第二届“核创论坛”学术研讨会顺利举行",
        'date': '2019-06-03',
        'content': [
          { 
            'subTitle': '',
            'txt': [],
            'pic': [
              require('../assets/6AD7132C2BEAA28269A0FBD094F_B68B2FB3_12F81.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '2019年5月31日，由核科学与技术学院核工程计算物理实验室（NECP）举办的第二届“核创论坛”学术研讨会在西安交通大学兴庆校区召开。本次会议议题为“堆芯源项与屏蔽技术研究方法与成果”，会议旨在针对反应堆屏蔽设计中的核心关键技术和学术问题交流现有研究成果以及最新研究动态。',
              '出席本次研讨会的学者主要为毕业于NECP实验室，并在全国多家高校、科研院所和公司任职的专家学者和技术人员，以及现有NECP实验室全体成员。',
              '会议伊始，西安交通大学曹良志教授致辞，对各位专家的到来表示热烈欢迎，并强调了本次会议的主题和目的。随后，进行了本次会议的技术报告。'
            ],
            'pic': [
              require('../assets/852FF1F991C772A035487807927_DFD984E0_15483.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '王晓霞首先做了题为“压水堆核电站核岛屏蔽设计”的报告。她简要介绍了屏蔽的基础知识，接着介绍了压水堆核电站核岛屏蔽分区、不同源项所采用的屏蔽设计方法等内容。'
            ],
            'pic': [
              require('../assets/C44C7C3F915306589B30A4B6A2F_C1E1BA5E_A03B.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '王昆鹏做了题为“核电厂放射性流出物审评软件研发”的报告。他针对各国审评软件普适性较低的问题研发了压水堆核电厂放射性流出物审评软件，并对软件的数据库和验证结果进行了介绍和展示。'
            ],
            'pic': [
              require('../assets/510C7C8AC233CF4786E313A41F8_8CDDFF3D_C901.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '肖锋做了题为“反应堆的源项以及屏蔽设计介绍”的报告。他从设计目标、设计内容、计算方法和创新方向等多角度多方位的向大家介绍了反应堆的源项以及屏蔽设计。'
            ],
            'pic': [
              require('../assets/5876707A23A733F4337B532294B_A99F6703_A88E.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '彭思涛做题为“衰变热及源项计算软件数据库开发”的报告。他从数据库自身结构组成入手分别向大家介绍了源项计算软件中需要考虑的数据种类和提出了在建立数据库中遇到的相关问题。'
            ],
            'pic': [
              require('../assets/485D28411E082974677AB4CFB07_2DD8422F_B25B.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '郑征做了题为“基于离散纵标的自动化全局减方差方法:功能、验证和应用”的报告。他针对蒙卡方法使用中出现的长历史问题、停堆剂量率和辐射源模型建立等问题提出了相应的解决方法，并得到了较好的验证结果。此外，郑征还简单介绍了基于人工智能的屏蔽设计方法。'
            ],
            'pic': [
              require('../assets/1ACA717DA0B19C0365F9D9960AE_791CD8C2_A4B9.jpg')
            ],
            'picTxt': ''
          },
          { 
            'subTitle': '',
            'txt': [
              '在讨论环节，与会专家和青年学者针对相关问题开展了深入详细的讨论。大家一致认为源项和屏蔽设计对反应堆设计和运行安全性具有重要的意义。',
              '“核创论坛”学术研讨会于2018年第一次举办，迄今已举办两届。研讨会在我国核能迅速发展和核电“走出去"国家战略指引下,为进一步推动我国核能关键技术发展,加强国内核能优势单位之间的交流做出了巨大的贡献。'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      }
    ],
    noticList: [  // 通知公告
      {
        'title': "PHYTRA5征文通知",
        'date': '2019-10-28',
        'content': [
          { 
            'subTitle': 'PHYTRA5将于2020年8月23日至8月26日在西安交通大学召开，欢迎大家踊跃投稿',
            'txt': [],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件一：征文通知',
            'txt': [
              'http://www.necphc.com/__local/8/31/02/E9D22259D149F5FDDC7F33698D7_99C75DC3_E0BED.pdf?e=.pdf'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件二：PHYTRA5官网',
            'txt': [
              'http://phytra5.csp.escience.cn/'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "中西部核学会联合体2019年学术年会",
        'date': '2019-06-28',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '陕西省核学会将于2019年8月23日（星期五）-26日（星期一）在陕西省榆林市召开中西部核学会联合体2019年学术年会暨陕西省核学会成立30周年纪念，欢迎广大核科技工作者参会。'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件一：关于召开中西部核学会联合体2019年学术年会暨陕西省核学会成立30周年纪念的通知',
            'txt': [
              'http://www.necphc.com/__local/3/87/EC/4E1187EFE6E97DB56DAF0E9B5FF_8A9C5CBF_51613.pdf?e=.pdf'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': " BEPU2020征文通知",
        'date': '2019-06-06',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2020年5月17-5月22日，BEPU2020将在SICILY,ITALY举办，欢迎大家踊跃投稿！',
              '详细信息请查看附件：'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件一：BEPU2020征文通知',
            'txt': [
              'http://www.necphc.com/__local/4/3F/4B/5FF6C0C30D7294FEBBE915E4D87_4F6CCE42_8B07E.pdf?e=.pdf'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件二：BEPU2020官网',
            'txt': [
              'http://www.nineeng.com/bepu2020'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "PHYSOR2020征文通知",
        'date': '2019-06-05',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              'PHYSOR2020将于2020年3月29日至4月2日在英国伦敦剑桥大学召开，欢迎大家踊跃投稿'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件一：征文通知physor2020.pdf',
            'txt': [
              'http://www.necphc.com/__local/1/F2/46/6819C7C09128284E4D906245887_85CA0D35_BE48E.pdf?e=.pdf'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件二：PHYSOR2020官网',
            'txt': [
              'http://www.physor2020.com/'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },
      {
        'title': "ICAPP征文通知",
        'date': '2019-06-03',
        'content': [
          { 
            'subTitle': '',
            'txt': [
              '2020 International Congress on Advances in Nuclear Power Plants将于2020年3月15日至19日在阿联酋阿布扎比召开，欢迎大家踊跃投稿',
              '详情请参见附件：'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件一：征文通知',
            'txt': [
              'http://www.necphc.com/__local/8/C5/F9/8D223DE32A00DA119C6E0A42847_14991C37_15E6E6.pdf?e=.pdf'
            ],
            'pic': [],
            'picTxt': ''
          },
          { 
            'subTitle': '附件二：ICAPP2020官网',
            'txt': [
              'http://www.icapp2020.org/'
            ],
            'pic': [],
            'picTxt': ''
          }
        ]
      },

    ],
    // 解决方案
    solutionList: [   // 解决方案列表
      { 
        "title": '中国实验快堆基准题建模计算',
        "date": '2020-02-11' ,
        "content": [
          {
            "subTitle": '需求背景',
            "txt": [
              '中国实验快堆（CEFR-China Experimental Fast Reactor）是中国第一座实验快堆，以PuO2-UO2为燃料，初始循环装料UO2，液态金属Na为冷却剂。其设计热功率65 MW，发电功率为20 MW。2010-2011年，CEFR进行了启动物理试验并发布了针对临界、控制棒价值、反应性系数评估的基准题。',
              'CEFR具有体积小、结构紧凑、不均匀性强的特点。由于模型基于真实实验堆，结构上相对复杂，在建模上有一定难度。堆芯结构如图1所示，由于组件排布不对称，需要对整个堆芯计算。此外，在试验的过程中，控制棒的棒位频繁调整，使堆芯计算的建模工作量较大。',
              'CEFR启动物理试验包含临界、控制棒价值、钠空泡反应性、温度反应性、替换反应性一系列实验。其中，临界试验相对独立，而三个反应性试验的数据处理都需要使用到控制棒的价值，控制棒价值的计算或测量是贯穿整个试验的重要环节。'
            ],
            "pic": [
              require('../assets/54BE1967FF3C0415A5C8E8F1401_ACFF8B28_49A1C.png'),
              require('../assets/92EF085DF2C1550486DE1D22E3C_770DB9C6_F36D.png')
            ],
            "picTxt": '图1 CEFR 堆芯示意图'
          },
          {
            "subTitle": '解决方案',
            "txt": [
              'SARAX基于中子输运方程的求解，增加SN的阶数使离散误差减小，克服该堆型的强非均匀性带来的问题。采用CMFD加速和小规模并行，以及程序结构优化，在保证精度的同时将计算时间控制在可接受范围。组件建模采用等效面积原则，将复杂的组件结构转化成同心圆结构，既保留了组件特征也简化了模型。堆芯建模则还原完整堆芯的布置，对712个组件分38层，生成712*38个节块。对于控制棒频繁动作的问题，SARAX在堆芯建模时，分别构造固定结构和运动结构，通过步距、步数、初始位置参数确定棒位，简化改变棒位的操作。',
              '在组件计算中，SARAX-TULIP可以计算均匀材料和一维非均匀材料。在计算均匀模型时，将各种材料按照体积份额均匀到整个组件中。在计算非均匀模型时，按照面积等效的原则，把燃料棒、包壳、钠冷却剂转换成同心圆环。对于均匀和非均匀模型，都要对组件在轴向上分层。以燃料组件为例，如图1所示，对一些微小结构进行简化使计算具有可行性。'
            ],
            "pic": [
              require('../assets/770BE50AB208C47E5C60EB46431_CD2B3F08_116DA.png')
            ],
            "picTxt": '图2 组件建模简化'
          },
          {
            "subTitle": '应用结果',
            "txt": [
              'CEFR基准题第一部分的临界计算基于燃料的装载过程。堆芯可以容纳燃料组件79组，在装料之前，燃料组件位置由不含燃料的mock-up组件占位，装料过程中按照既定顺序依次替换，在装入第72组时达到并超过临界，需要用控制棒调节至临界。SARAX程序对装料过程进行了全程模拟。其中，超临界后通过调节棒调整临界的计算结果与实测值对比结果如图2所示。通过与实测值的对比可见，SARAX在临界计算中不仅结果准确，而且误差稳定，控制在150pcm左右。',
              '基准的第二部分是对组件出现空泡带来的反应性变化的预测。具体为，在活性区选取若干燃料组件，分别替换成不含钠的组件，将替换前后的有效增殖因子做差获得空泡反应性。计算值与测量值对比结果见图3。空泡组件位置在图中由左至右对应堆芯中由中心至边缘的五个位置，空泡反应性为负值，绝对值由左至右呈减小趋势。对比可见，SARAX在预测空泡反应性系数中具有结果可靠、系统误差稳定的特点，最大误差处的偏差也控制在8.2pcm以内。'
            ],
            "pic": [
              require('../assets/C5DD76A39611766BC846C0B3488_A3D50813_46F9.jpg'),
              require('../assets/841C0140E1B18B44241932F0974_10E81F04_5B8A.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '对控制棒价值的测定是贯穿CEFR启动物理试验的重要部分。堆芯中有8个控制棒，分别为2个调节棒（RE）、3个补偿棒（SH）、3个安全棒（SA），按照功能和落棒规律进行分组，分别试验。计算结果与实测值的对比如图4所示。计算值落在测量值的误差范围内。'
            ],
            "pic": [
              require('../assets/3A3DE99B55E042CE807B2A3D77B_4F818F00_7A72.jpg')
            ],
            "picTxt": '',
          },
          {
            "subTitle": '',
            "txt": [
              '除基准题要求的控制棒价值预测以外，还需要对控制棒在不同高度处的微分价值、积分价值进行测定，拟合出价值曲线，如图5所示，以用于后续各种反应性的测定。为使拟合更加准确，需要选取尽量多的测量点。SARAX在堆芯计算中将固定结构和移动结构分别定义的特点使此过程的工作量降低，从而可以采取较小的步长，得到更准确的价值曲线。利用已有的控制棒价值，对基准题中提出的其它反应性试验进行预测，如温度反应性系数。计算值得出升温过程温度反应性系数与实测值偏差为0.5 pcm/K，小于实测值的不确定度±0.6pcm/K，吻合较好。'
            ],
            "pic": [
              require('../assets/D289389D6E7C471EC08D8A1322A_1E06C37A_6A09.jpg')
            ],
            "picTxt": ''
          },
          {
            "subTitle": '',
            "txt": [
              '通过使用SARAX对CEFR启动物理试验基准题的计算，验证了SARAX在解决此类小体积、非均匀性强的快中子反应堆的临界计算问题上具有先进性。SARAX的建模方式可以适应此类控制棒频繁动作的需求，一定程度上降低使用者的工作量。从计算结果与参考值的对比分析中可以得出，SARAX具有计算结果准确、系统误差稳定的优势，在指导工程实践中具有良好的应用前景。'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      },
      {
        "title" : '基于相似测量数据的高准确度和高置信度的工程问题数值模拟',
        "date": '2020-02-11',
        "content": [
          { 
            "subTitle": '需求背景',
            "txt": [
              '在反应堆的设计和运行过程中，确保反应堆的运行工况不超过安全设计限值是反应堆设计的重要目标。新型反应堆的设计原则之一就是要能够安全有效地运行，需要时刻保持反应堆的关键参数处在安全限值之内。一旦运行参数超过安全设计限值，就会导致反应堆功能的失效，从而引发事故。因此，安全限值的选取就是反应堆安全分析的重中之重，然而实践中这一限值的选取往往困难重重。',
              '由于使用工具以及理论的局限性，客观事物的真实值是无法准确测量的。设计程序的计算结果与实际结果往往存在一定的误差，这个误差主要来自于理论上的近似，和输入参数的不确定度。对于核设计程序SARAX来说，理论方法采用十分精确的输运方法，所带来近似较小，而另一方面，输入参数主要包括核数据库和几何参数。几何参数的测量可以通过测量工具的发展而变得精确，而核数据往往带有一定不确定度，如图1和图2所示，这一不确定度导致核数据的置信度不能满足新型反应堆的安全需求，因而就亟待发展针对核数据的调整方法，以提升核数据的精度和置信度，满足反应堆的设计要求。'
            ],
            "pic": [
              require('../assets/6321785913F5ECF5A664B5FA85F_BF395D38_4003F.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '提升核数据的精度和置信度是为了保证核设计程序计算结果的准确性。对于一个新开发的核设计程序来说，必须要进行目标堆芯确认工作。传统的确认方法需要针对目标堆芯进行等效的物理模拟实验，而这一过程耗时耗力。而依靠核数据调整方法，能够充分利用已有的实验数据，来验证新的核设计程序。'
            ],
            "pic": [],
            "picTxt": ''
          },
          { 
            "subTitle": '解决方案',
            "txt": [
              '敏感性与不确定性分析是核数据调整理论的前提。敏感性分析就是分析程序输入输出之间的线性关系，通常采用广义微扰理论或直接数值扰动理论计算，敏感性系数计算的表达式如所示。不确定分析是指量化输入不确定度对输入的不确定度，采用三明治法则如所示或者统计抽样方法。敏感性与不确定性是核数据调整所用到的基本信息。'
            ],
            "pic": [
              require('../assets/317C8A8409A5DE48833B089EAF4_961B18C8_4408.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '在众多物理实验中，只有与目标堆芯在物理学上相似的实验才会被采用。量化不同实验之间的相似程度就是相似性分析，所依据的信息就是系统的敏感性与不确定性，其三种不同的定义如式，和所示。输出对输入的敏感性系数相近且不确定度成分类似的堆芯，才能被称为相似的两个堆芯。通过这样的筛选，从众多物理实验中筛选出符合相似性要求的堆芯，才能被用来做核数据调整。'
            ],
            "pic": [
              require('../assets/CAFE72E05B3351637CD80D2703A_A870DA44_6B29.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '核数据调整需要面临众多变量的回归问题。每一个核素拥有不同的能群，不同的反应道。那么反应堆中的上百种核素就成为了上万个输入参数，如何使用恰当的数学模型对多个实验进行回归分析，就是核数据调整。目前大多数调整理论都是基于广义最小二乘法如式所示，推导出满足所有实验的误差降到最小时的输入参数，也就是调整后的核数据了。'
            ],
            "pic": [
              require('../assets/ED1B4DED5E108F2692E7899BF66_D9D718B3_34E7.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '应用结果',
            "txt": [
              '将中国示范快堆作为调整的目标堆芯，首先从国际评价核物理试验数据中选择钠冷快堆实验进行敏感性与不确定性分析。初步选择了JOYO系列实验装置（包括JOYO-64和JOYO-70两个临界堆芯）和ZPPR系列实验装置（包括ZPPR-9、ZPPR-10A、ZPPR-10B、ZPPR-10C和ZPPR-18A等5个临界堆芯）的共7个临界实验，作为核数据调整的初始实验数据。',
              '采用核设计程序SARAX对上述实验装置进行模拟计算，然后在敏感性与不确定性分析平台UNICORN上进行分析。量化了堆芯keff对多群微观截面的相对敏感性系数以及核数据协方差导致的不确定度。如图3和图4所示，对比两种不同系列的堆芯可以发现，ZPPR系列的堆芯收Pu-239的影响较大，而JOYO系列的堆芯受U-238的影响较大。'
            ],
            "pic": [
              require('../assets/5FF70EE58339B5CD61065338EB8_DB754158_F40A.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '基于上述针对快堆实验装置JOYO和ZPPR系列基准实验装置的临界堆芯和中国示范快堆堆芯keff的敏感性和不确定性分析，量化并格式化输出了堆芯keff的相对灵敏度系数向量文件。结合快堆计算多群截面相对协方差数据库，采用相似性分析程序UNICORN_Similarity评估JOYO和ZPPR系列基准实验装置的临界堆芯和中国示范快堆堆芯的相似性系数，其分析结果如表1所示。由相似性分析结果可知：不同的相似性系数类型（Esum,Gm和Ck）给出的相似性分析结果在数值上存在较大的差异，但数值结果的界限表明，ZPPR系列基准实验装置的临界堆芯与中国示范快堆堆芯的相似程度明显高于JOYO系列基准实验装置的临界堆芯。'
            ],
            "pic": [
              require('../assets/7D17EB5DCD64741806C730DF29B_5C1DFD8E_A82C.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '根据核数据调整方法的理论基础，用于核数据调整的基准实验装置，实质是增加了对核数据库截面评估的宏观检验，故核数据库的不确定度水平在核数据调整后也得到一定的改善，主要的两个反应道调整结果如图5和图6所示，调整量全部处在不确定度范围之内，符合常理。因此，核数据库的不确定度对ZPPR实验装置的临界堆芯和中国示范快堆堆芯keff引入的不确定度水平在核数据调整前后的对比如表2所示。由表中数值结果可知，通过核数据库的调整，ZPPR系列实验装置临界堆芯和中国示范快堆堆芯keff的相对不确定度水平显著降低（见表3）：ZPPR系列实验装置临界堆芯keff的相对不确定度水平由超过1.20%降低到0.15%左右；中国示范快堆堆芯keff的相对不确定度水平由0.77%降低到0.20%。截面的相对调整量均落在其相对不确定度范围内。',
              '核数据库的调整是根据截面的评估精度进行的符合现实情况的合理调整，既在微观层面上满足了截面的相对调整量与对应的相对不确定度的一致性，又在宏观层面上显著改善了核反应堆物理计算程序对宏观检验装置的计算精度。'
            ],
            "pic": [
              require('../assets/CD4CC9B503B0FB356A78864B1EB_C4906F26_4500.gif')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/E0B2D113A32040064068ED6EFC3_A3F36E31_5941.gif')
            ],
            "picTxt": ''
          }
        ]
      },
      {
        "title" : '面向计算程序与应用场景的定制化核数据库制作',
        "date": '2020-02-11',
        "content": [
          { 
            "subTitle": '需求背景',
            "txt": [
              '核数据库作为核反应堆物理计算程序的基础输入参数，始终影响着计算结果的准确度。对于一些经典的核反应堆物理计算程序，如WIMS程序和MCNP程序等，其使用的核数据库是以特定的格式进行描述的，即WIMS-D格式和ACE格式。这些格式用于存储计算程序所需要的基本数据，这些数据与程序中所使用的计算方法乃至应用对象都有一定的关系。如在WIMS-D格式中存储了共振积分表，用于进行基于等价理论的共振计算，所面向的应用场景为商业压水堆。',
              '目前，随着数值计算方法以及计算机性能的快速发展，核反应堆物理计算程序对核数据库中的内容也提出了新的要求，仅使用传统格式的核数据库无法满足需求。因此，为了能使新的数值方法兼顾计算精度和计算效率，必须对核数据库进行定制。例如，对于一个确定论程序和其应用场景，其数据库的格式（存储数据类型）、能群结构、燃耗链等数据都需要进行针对化的加工处理，以发挥出该程序的最大能力。',
              '一般情况下，使用传统核数据处理程序对评价核数据库进行处理可得到核数据库，如NJOY、AMPX等。传统的核数据处理程序只能产生传统的核数据库，缺乏针对性的核数据库定制功能，在应用于一些工程实际问题时，局限性较大。'
            ],
            "pic": [],
            "picTxt": ''
          },
          { 
            "subTitle": '解决方案',
            "txt": [
              '在核数据库定制过程中，面向不同的计算程序和应用场景，需要对一类或者多类数据库参数进行定制化的加工处理。下面简要举例4类可定制化内容。'
            ],
            "pic": [],
            "picTxt": ''
          },
          { 
            "subTitle": '1) 数据库格式',
            "txt": [
              '数据库格式一方面规定了数据在文本中的排列方式，另一方面也归定了每一个位置上数据的物理意义，计算程序中所使用的方法和数据库中的各类数据密切相关。针对压水堆组件程序LOCUST，为了满足其计算方法的需求，使用Atlas-C产生了连续能量点截面数据，能群、核素相关的Goldstein-Cohen因子（中间共振因子）数据，针对1H和16O的高阶（P1-P5）散射矩阵数据、核素相关裂变谱数据、共振弹性散射核（Resonance Elastic Scattering Kernel）数据等，并将上述数据统一整合到新的格式中。针对蒙特卡罗粒子输运计算程序MCX，为了精确计算热谱固定源问题，使用Atlas-C产生了连续角度分布S(α,β,T)表代替离散角度分布S(α,β,T)表，用于解决如图1所示问题。'
            ],
            "pic": [
              require('../assets/35443D19E7EAA9A131BDE4A1991_D98541B1_D9BB.png')
            ],
            "picTxt": '图 1 基于连续角度分布S(α,β,T)表与离散角度分布S(α,β,T)表的中子通量分布'
          },
          { 
            "subTitle": '2) 能群结构',
            "txt": [
              '对于确定论程序，其核数据库的能群结构是最为重要的参数之一。能群结构具有两重意义，即能群的个数和能群边界的排布位置。能群结构直接影响着计算效率与精度，较多的能群个数通常会得出精度较高的计算结果，但其所需计算时间更多；较少的能群个数虽然所需计算时间较少，但需要非常谨慎的选择能群边界才能够得出精度较高的计算结果。能群结构的适用性不但与计算程序有关也与应用场景具有密切联系，为了能够获得能群数少且精度高的能群结构，需要针对能群结构进行定制化的加工处理。',
              '针对特定程序和目标问题，采用响应贡献法进行能群优化。首先计算目标问题的响应通量，对响应通量进行等面积划分以获得优化后的能群结构。响应通量的表达式如公式(1)所示'
            ],
            "pic": [
              require('../assets/D163F92D91791BCB322FF0112B4_9737FC67_1A4A.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '典型压水堆栅元问题的响应通量如图2所示'
            ],
            "pic": [
              require('../assets/33A3F7042252DD0F14A83A11459_92A925FA_946E.jpg')
            ],
            "picTxt": '图2 典型压水堆栅元问题的响应通量'
          },
          { 
            "subTitle": '应用结果',
            "txt": [''],
            "pic": [ ],
            "picTxt": ''
          },
          { 
            "subTitle": '1) 数据库格式',
            "txt": [
              '共振弹性散射核对于热谱问题的keff以及燃料温度系数都有着非常大的影响，但目前大部分反应堆物理计算程序都无法考虑共振弹性散射核。因此，针对不同的计算程序，对其核数据库格式进行定制。',
              '对于WIMS、DRAGON等传统程序所使用的WIMS-D格式数据库，对其中的共振积分表与散射矩阵进行定制。基于定制后的WIMS-D格式数据库，利用DRAGON5对Mosteller基准题进行建模计算，基准题中包含了不同富集度的UO2、反应堆回收MOX和武器级MOX单栅元基准题，温度为600 K和900K。分别基于传统数据库和优化数据库，计算了上述问题的无限增殖因子和燃料温度系数（表1-表3），燃料温度系数的定义为'
            ],
            "pic": [
              require('../assets/00BC7CDA4CBFA1587064B1CDC4C_E249D13E_1A34.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [''],
            "pic": [
              require('../assets/93E963E954429662357C4B6CC98_30A068B2_107CC.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [''],
            "pic": [
              require('../assets/B8D935377B5329F5FF2770D409C_86251DFA_DF53.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [''],
            "pic": [
              require('../assets/4DB7C769D1DBD6BC3146B648CB3_AA0639B0_CEDB.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '对LOCUST程序所使用的NECL格式数据库中的散射矩阵进行定制。基于定制后的数据库对FCM燃料栅元问题（见图3）进行建模计算，计算了无限增殖因子和燃料温度系数，见表4。'
            ],
            "pic": [
              require('../assets/F091B277FCFB51681F8413A2376_B46D6B98_173EB.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [''],
            "pic": [
              require('../assets/5996CFA7ED0EC50308CFA560DBF_C8249796_5282.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '上述结果显示，定制后的数据库能有效地考虑共振弹性散射核，计算结果能显著提高计算精度。'
            ],
            "pic": [
              require('../assets/5996CFA7ED0EC50308CFA560DBF_C8249796_5282.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '2) 能群结构',
            "txt": [
              'LOCUST程序目前所使用的是广泛应用的WIMS-69能群结构，为了能将程序的计算精度发挥至最大，对LOCUST程序的能群结构进行优化，得到NECP-69能群结构。针对VERA #2系列基准题进行特征值计算，结果见表5。结果显示，能群结构优化后能显著提高程序的计算精度。'
            ],
            "pic": [
              require('../assets/6C3690296693202B3C3ACCE9401_7D485F5F_5DBE.png')
            ],
            "picTxt": ''
          }
        ]
      },
      {
        "title" : '基于蒙特卡罗-确定论耦合方法的探测器响应高置信度模拟',
        "date": '2020-02-12',
        "content": [
          { 
            "subTitle": '需求背景',
            "txt": [
              '核电厂和一些辐射装置需要在屏蔽层内外设置探测器。准确高效的探测器响应计算，有利于指导屏蔽装置、辐照装置设计和监测功率等。探测器的应用场景如下：',
              '1) 堆内探测器，用于监测堆芯精细功率分布以及三维全堆功率重构；',
              '2) 堆外探测器，用于启堆和功率运行期间堆芯中子注量率水平和轴向功率偏移的监测；',
              '3) 其他各类辐射装置，用于探测特定位置特定能量段的响应。'
            ],
            "pic": [
              require('../assets/3633564D1013BF5C5A76D66ABC1_4738D0E5_21571.png'),
              require('../assets/FCFDFE92460A128D5AF86CDBBA3_CC165853_61FF4.png')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/818E5C3DFE681D3D129AC4F268C_D339E0D2_9C8CE.png'),
              require('../assets/ACAEB3B7A117555664C763AF56F_E7FFFD59_A88F.jpg')
            ],
            "picTxt": ''
          },
          { 
            "subTitle": '',
            "txt": [
              '探测器响应计算的核心是对于给定辐射屏蔽装置，根据源分布、几何、材料，进行粒子输运计算。常规辐射装置的粒子输运计算，主要考虑中子和光子。对于粒子输运计算，一般分为两大类方法。确定论方法和蒙特卡罗方法，其中确定论中最常用的方法是离散纵标方法。针对于辐射装置中的各类探测器响应的计算，存在以下难点：',
              '1) 离散纵标方法一般适应于典型规则几何或典型能谱，并且离散纵标方法对角度的离散会带来射线效应；',
              '2) 蒙特卡罗方法几何描述能力强，计算精度高，但对于探测器相应计算，问题规模大，从源的位置到探测器的位置，通量下降超过7个数量级，探测器体积较小，且不同探测器一般对特定的一段能量区间敏感，蒙特卡罗方法存在小概率深穿透问题，投入大量粒子计算结果仍然偏低。'
            ],
            "pic": [],
            "picTxt": ''
          },
          { 
            "subTitle": '解决方案',
            "txt": [
              '自主开发的蒙特卡罗-确定论耦合粒子输运计算程序MCX，用于探测器计算，解决装置的几何复杂性，适应各类装置的能谱复杂性。蒙特卡罗-确定论耦合方法计算流程如图 1所示，用户首先对计算的问题进行建模，MCX根据模型自动生成离散纵标程序计算所需的网格，根据材料和多群屏蔽核数据库生成多群宏观截面，然后调用离散纵标程序进行共轭计算。如果需要计算多个探测器，在共轭计算之前，先进行前向输运计算，采用前向通量对共轭源进行偏倚。根据共轭通量，生成权窗参数和源偏倚参数，蒙特卡罗程序利用模型、权窗参数、源偏倚参数和连续能量截面进行粒子输运计算，得到探测器响应。',
              'MCX计算探测器响应的优势如下：',
              '1) 采用蒙特卡罗-确定论耦合方法，结合两种方法的优点，效率和精度均高于单独的蒙特卡罗方法或者确定论方法；',
              '2) 用户使用方便，只需要采用CSG几何进行建模，不需要划分计算网格，不需要填写经验性的减方差参数；',
              '3) 千核并行效率90%，能够高效计算大规模问题；'
            ],
            "pic": [
              require('../assets/8955582E4CA8F76535540AFBCCD_566C16C6_6796.jpg')
            ],
            "picTxt": '图 1蒙特卡罗-确定论耦合方法计算流程'
          },
          { 
            "subTitle": '应用结果',
            "txt": [
              '采用MCX和MCNP程序计算压水堆屏蔽问题，俯视图如图 2所示。采用蒙特卡罗方法和蒙特卡罗-确定论耦合方法计算的通量分布如图 3所示，直接采用蒙特卡罗方法进行模拟，通量较低的位置没有统计值，蒙特卡罗-确定论耦合方法能够得到准确的统计值。辐照监督管和中子剂量仪的能谱如图 4和图 5所示，MCX采用蒙特卡罗-确定论耦合方法，MCNP采用权窗自动生成技巧。在高能量段，相比于MCX，MCNP的统计方差较大，统计值偏低。对于该问题，MCX计算探测器相应的精度和效率高于MCNP。'
            ],
            "pic": [
              require('../assets/FD7247CCE44A165828BDC2E7165_1C577394_3387.png')
            ],
            "picTxt": '图 2 HBR-2布置图'
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/C3E290067F4F54B3B4C4FD95059_6CA7DBE1_13E01.png')
            ],
            "picTxt": '图 3 蒙特卡罗方法直接模拟和蒙特卡罗-确定论耦合方法模拟得到的中子通量分布对比'
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/E94178B02A1D874328B830BA811_A0711232_CD95.png')
            ],
            "picTxt": '图 4 辐照监督管的中子能谱'
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/5418FBB2454C7AB385E9DD8502B_9CB0380A_CDB8.png')
            ],
            "picTxt": '图 5 中子剂量仪的中子能谱'
          }
        ]
      },
      {
        "title" : '福清CNP1000压水堆第一机组堆芯物理计算',
        "date": '2020-02-13',
        "content": [
          { 
            "subTitle": '需求背景',
            "txt": [
              '在现阶段商用压水堆的运行过程中，需要利用到压水堆堆芯燃料管理软件在换料设计计算、循环跟踪计算等重要功能，一方面需要燃料管理软件为换料设计提供详细的堆芯运行数据和寿期末堆芯组件燃耗数据，另一方面根据试验数据和堆芯运行实测数据与理论计算结果的比较，验证程序计算方法的准确性，并检验测量数据处理的正确性。而燃料管理软件在解决实际工程问题时，会面临诸多挑战，例如反应堆内材料种类复杂问题、建模输入复杂问题、计算精度问题等。Bamboo-C软件作为一款以工业应用为导向的压水堆堆芯燃料管理软件，现将其应用与福清CNP1000压水堆第一机组堆芯物理计算这一实际工程问题中，在验证计算软件准确性的同时，为电站换料设计及运行提供有力支持。',
              '福清核电站位于福建中部沿海福州福清市，工程规模为6台百万千瓦级压水堆核电机组，1至4号机组为二代改进型压水堆CNP1000，5、6号机组为华龙一号。CNP1000是我国在现有压水堆技术上，借鉴国际核电发展新成果，设计建造的“二代加改进型”压水堆堆型。福清CNP1000第一机组第一循环装有157盒17×17排列的燃料组件，按富集度的不同分为三种类型在堆芯内以高泄露布置方案棋盘式排列。第二循环堆芯燃料装载拔出了所有可燃毒物棒，空出的导向管由阻力塞塞住。'
            ],
            "pic": [],
            "picTxt": ''
          },
          { 
            "subTitle": '解决方案',
            "txt": [
              '基于Bamboo-C软件对如下关键堆芯运行参数进行数值模拟：',
              '1. 对福清CNP1000堆芯启动物理试验（C1-C3）、运行历史及运行跟踪测量参数整理(C1-C3)；',
              '2. 根据启动物理试验数据进行模拟计算，并与实测对比，包括：临界硼浓度(CBC)、温度系数(ITC、MTC)、硼微分价值(DBC)、控制棒价值(RCCA Worth)等；',
              '3. 根据运行历史进行功率历史跟踪计算，并与实测对比，包括循环硼降曲线、堆芯不同燃耗下组件功率分布等。',
              '堆芯燃料及反射层建模示意图如图1所示，其中最外侧浅蓝色组件为反射层组件，其中深蓝色阴影部分为围板，绿色部分为吊篮。'
            ],
            "pic": [
              require('../assets/75A79AD7552ED035A964E387B29_4C94CB47_55BC2.png')
            ],
            "picTxt": '图1 堆芯燃料及反射层建模示意图'
          },
          { 
            "subTitle": '',
            "txt": [
              '堆芯轴向建模示意图如图2所示，建模过程中考虑将轴向格架按照真实位置描述，以得到更精准的功率分布结果。'
            ],
            "pic": [
              require('../assets/299AB7DEE3AE86A3EB9E343316C_303DE436_19653.png')
            ],
            "picTxt": '图2 堆芯轴向建模示意图'
          },
          { 
            "subTitle": '',
            "txt": [
              '组件内左下2×2径向格架显式建模示意图如图3所示，格架径向显式准确地描述了格架对物理及热工计算的影响，从而得到更精准及计算结果。'
            ],
            "pic": [
              require('../assets/AA14867723B2DE1D4B32A3B6ACE_309E3675_250B6.png')
            ],
            "picTxt": '图3 组件径向左下2×2示意图（绿色为格架；蓝色为慢化剂；灰色为包壳；红色为气隙；黄色为芯块）'
          },
          { 
            "subTitle": '应用结果',
            "txt": [
              '1）启动物理试验',
              '福清CNP1000_U1_C1-C3启动物理试验计算结果如表格1所示，所有计算结果均符合工程计算要求（CBC计算误差<±50ppm；MTC计算误差<±3.6pcm/K；控制棒价值计算相对误差<±10%）。'
            ],
            "pic": [
              require('../assets/FB791F459CA1AC133600083A7C3_6CE8B75E_CAF7.jpg')
            ],
            "picTxt": '表1 C1-C3启动物理实验计算结果'
          },
          { 
            "subTitle": '',
            "txt": [
              '2）功率历史跟踪',
              '福清CNP1000_U1_C1-C3功率历史跟踪硼降曲线计算误差统计结果如图4所示，98.2%计算结果误差在工程要求以内（±50ppm）。',
              '功率分布计算误差统计图如图5和图6所示，功率水平分为功率>0.9时有99.7%计算结果误差在工程要求之内（±5%），功率水平分为功率<0.9时有99.7%计算结果误差在工程要求之内（±8%）。'
            ],
            "pic": [
              require('../assets/3CA06BA8D353B19DD334C0B845A_F1D3A1CC_1079A.jpg')
            ],
            "picTxt": '图4 C1-C3硼降曲线误差统计图'
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/8D8C92EC77330B2A354EB28FFA4_DCE4B42E_1096E.jpg')
            ],
            "picTxt": '图5 C1-C3功率大于0.9误差统计图'
          },
          { 
            "subTitle": '',
            "txt": [],
            "pic": [
              require('../assets/1570EF9245B996BE422A6C9899C_13C9840B_14B74.jpg')
            ],
            "picTxt": '图6 C1-C3功率小于0.9误差统计图'
          },
          { 
            "subTitle": '',
            "txt": [
              '通过福清CNP1000_U1_C1-C3启动物理试验及功率历史跟踪模拟计算，计算结果与实测结果对比表明：Bamboo-C软件应用于工程实际问题时，计算结果与实测结果符合得很好，温度系数、控制棒价值、硼降曲线等参数的偏差均在工程要求范围内，计算结果可靠。Bmaboo-C软件有能力应用于换料设计及运行跟踪等工程问题，计算得到的反应堆堆芯运行状态和参数对指导反应堆运行具有参考价值。'
            ],
            "pic": [],
            "picTxt": ''
          }
        ]
      }
    ]
  },
  mutations: {
  },
  getters: {
  },
  actions: {
  },
  modules: {
  }
})
