import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-plus/packages/theme-chalk/src/index.scss';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import Swiper, {Navigation, Pagination} from 'swiper';
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.min.css'

import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI)
Vue.use(VueAwesomeSwiper)
Swiper.use([Navigation, Pagination]);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
